/*
https://iot.aizoteq.com/google?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fhomegraph&redirect_uri=https%3A%2F%2Fdevelopers.google.com%2Foauthplayground&response_type=code&client_id=4hr5ambggej4fukgqf7ra6ievi&access_type=offline
https://oauth-redirect.googleusercontent.com/r/aizo-ac18f
*/


import React, { Component } from 'react'
// import { authenticateUser } from "./Cognito"
import './styles.css';
// import fetchTimeout from 'fetch-timeout';
import axios from "axios";
// import Cookies from 'js-cookie';
//import cookie from 'react-cookie';
// import fetch from 'node-fetch';
// import Signup from './Signup'
// import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import logo from './logo.png'


import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import Button from 'react-bootstrap/Button';
import PuffLoader from "react-spinners/PuffLoader";

import toast, { Toaster } from 'react-hot-toast';
import { ThirtyFpsSelect } from '@mui/icons-material';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

Amplify.configure(awsconfig);












class Signin extends Component {
  constructor (props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.handleSigninSubmit = this.handleSigninSubmit.bind(this)

    this.GetAuthCode = this.GetAuthCode.bind(this)
    this.GetToken = this.GetToken.bind(this)
    // this.signUp = this.signUp.bind(this)
    // this.onChange = this.onChange.bind(this)

    this.state = {
      name:'',
      phone:'',
      email: '',
      password: '',
      password2:'',
      confirmationCode:'',
      loading: false,
      redirectUri:'',
      url_state:'',
      requestFrom:'',
      google:false,
      mode:'login'
    }
  }
  
  onChange = (e,key)=>{
    //console.log('key:'+key+' value:'+e.target.value)
    this.setState({ [key]: e.target.value })
  }


  signIn = async (e)=>{
    try {
      e.preventDefault()
    } catch (error) { }
    
    if(this.state.email == ''){
      toast('Please enter username')
      return
    }else if(this.state.password == ''){
      toast('Please enter password')
      return
    }
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false })
    }, 15000);
    console.log('------------Signing In---------------')
    try {
        const user = await Auth.signIn(this.state.email,this.state.password);
        console.log('user signing success', JSON.stringify(user));
        
        ReactSession.set("username", this.state.email);
        window.open('/devices','_self');
    } catch (error) {
      if(error.code == 'UserNotConfirmedException'){
        this.setState({ mode: 'confirm' })
        this.setState({ loading: false })
        this.resendCode(null)
      }else{
        console.log('error signing in', error);
        toast('Incorrect username or password')
        this.setState({ loading: false })
      }

        //window.open('/','_self');

    }
    return
}

signUp = async (e)=>{
  e.preventDefault()
    if(this.state.name == '' || this.state.email == '' || this.state.phone == '' || this.state.password == '' || this.state.password2 == ''){
      console.log('name:',this.state.name)
      console.log('email:',this.state.email)
      console.log('phone:',this.state.phone)
      console.log('password:',this.state.password)
      console.log('password:',this.state.password2)
      toast('Please fill all feilds')
      return
    }
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false })
    }, 15000);
    console.log('------------Signup---------------',this.state.phone)
    try{
    await Auth.signUp({
      username: this.state.email,
      password: this.state.password,
      attributes: {
        name: this.state.name,
        phone_number: this.state.phone,
        email: this.state.email,
        'custom:alexa_code':'default',
        'custom:google_code':'default',
      }
    }).then(res => {
      console.log('successful signup: ', res)
      this.setState({mode:'confirm'})
      //this.refs.toast_green.show('SignUp success',DURATION.LENGTH_LONG);
    }).catch(err => {
      console.log('error signing up!!!..: '+this.state.email, err)
      if(err.code =='UsernameExistsException'){
        toast('User already exists')
      }else if(err.code == 'InvalidParameterException'){
        toast(err.message)
      }else{
        toast(err.message)
      }
    });
  } catch {
  }  
  this.setState({loading: !this.state.loading,});
    return

}

confirmUser = (e)=>{
  try { e.preventDefault()} catch (error) {}
  
  console.log('Confirming signUp')
  if(this.state.confirmationCode == ''){
    toast('Please enter confirmation code');
    return
  }
  Auth.confirmSignUp(this.state.email, this.state.confirmationCode).then(res => {
      console.log('successful SiginUp confirmation: ', res)
      toast('Confirmation success')
      this.setState({mode:'signin'})
      this.signIn('null')

    }).catch(err => {
      toast('Confirmation code Invalid');
      console.log('error confirming user SiginUp  : ', err)
    })
}

 resendCode = async()=> {
  if(this.state.email == ''){
    toast('Please enter email');
    return
  }
  this.setState({loading: !this.state.loading,});
  await Auth.resendSignUp(this.state.email).then(() => {
    toast('Confirmation code send')
  }).catch(err => {
    if (! err.message) {
      toast('Error requesting new confirmation code')
    } else {
      toast('Error requesting new confirmation code')
    }
  })
  this.setState({loading: !this.state.loading,});
}


forgetPassword = async (e)=>{    
  try { e.preventDefault()} catch (error) {}
  if((this.state.email =='')|| this.state.email == undefined){
    toast('Please enter email')
    return
  }
  // let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // if (reg.test(this.state.email) === false) {
  //   console.log("Email is Not Correct");
  //   toast('Please enter valied email')
  //   return
  // }
  // else {
  // }

  try {
    this.setState({loading:true});
    let res = await Auth.forgotPassword(this.state.email).then(() => {
      this.state.loading = false
      this.setState({mode:'forgetConfirm'})
    }).catch(err => {
      toast('Password reset error');
      this.setState({loading:false})
    })

    

  } catch (error) {
    //onError(error);
    //setIsSendingCode(false);
    console.log('Mail Failed to send to '+this.state.email+' ',error.code)
    if(error.code == 'LimitExceededException'){
      toast('reqest Limit exeed')
      this.setState({loading:false});
    }
    else{
      toast(error.code)
      this.setState({loading:false});
    }
  }

  }

  
  forgetConfirm = async(e)=>{
    try { e.preventDefault()} catch (error) {}
    if(this.state.password == this.state.password2){
      if(this.state.conformationCode == ''){
        toast('Enter confirmation code')
        console.log('Enter confirmation code ',this.state.confirmationCode)
        return
      }
      if(this.state.password.length<8){
        toast('Minimum password length is 8')
        console.log('Password length is short')
      }
      else
        {
        console.log('Confirm '+this.state.email+' '+this.state.confirmationCode+' '+this.state.password)
        try {
          await Auth.forgotPasswordSubmit(
            this.state.email,
            this.state.confirmationCode,
            this.state.password
          );
          console.log('New password saved')
          this.setState({mode:'login'})
          toast('New password saved')
          //setConfirmed(true);
        } catch (error) {
          console.log('Confirm error',error)
          if(error.code == 'LimitExceededException'){
            toast('Reqest Limit exeed')
          }
          else if (error.code == 'CodeMismatchException'){
            toast('Invalid confirmation code')
          }
          else{
            toast(error.code)
          }
          //setIsConfirming(false);
        }
      }
    }
    else{
      toast('Password not match')
    }
  }






  handleSigninSubmit (e) {
    e.preventDefault()
    if(this.state.email == ''){
      toast('Please enter username')
      return
    }else if(this.state.password == ''){
      toast('Please enter password')
      return
    }
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false })
    }, 20000);
    console.log('Entered:', this.state)
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get('state');
    const redirect_uri = queryParams.get('redirect_uri');
    const scope = queryParams.get('scope');
    const response_type = queryParams.get('response_type');
    const client_id = queryParams.get('client_id');
    const access_type = queryParams.get('access_type');


    console.log('------------queryParams---------------',window.location.search)
    console.log('state: ',state);
    console.log('redirect_uri: ',redirect_uri);
    console.log('scope: ',scope);
    console.log('response_type: ',response_type);
    console.log('access_type: ',access_type);
    console.log('client_id: ',client_id);

    // var url = redirect_uri+'?code='+'sayyidyaseen@gmail.com'+'&state='+state
    this.url_state = state
    this.state.redirectUri = redirect_uri
    this.GetAuthCode(redirect_uri,state)

  }

  async componentDidMount(){
    console.log('props:',this.props.agent)
    document.title = "AiZo"

    const available_redirect_uri_1 = "https://oauth-redirect.googleusercontent.com/r/aizo-ac18f"
    const available_redirect_uri_2 = "https://oauth-redirect-sandbox.googleusercontent.com/r/aizo-ac18f"
    const available_redirect_uri_3 = "https://developers.google.com/oauthplayground"
    const available_redirect_uri_4 = "https://oauth-redirect.googleusercontent.com/r/aizo-ac18f"
    
    const queryParams = new URLSearchParams(window.location.search);
    const redirect_uri = await queryParams.get('redirect_uri');

    const state = queryParams.get('state');
    const scope = queryParams.get('scope');
    const response_type = queryParams.get('response_type');
    const client_id = queryParams.get('client_id');
    const access_type = queryParams.get('access_type');


    // console.log('------------queryParams---------------')
    // console.log('state: ',state);
    // console.log('redirect_uri: ',redirect_uri);
    // console.log('scope: ',scope);
    // console.log('response_type: ',response_type);
    // console.log('access_type: ',access_type);
    // console.log('client_id: ',client_id);

    if(redirect_uri == available_redirect_uri_1 || redirect_uri == available_redirect_uri_2 || redirect_uri == available_redirect_uri_3 || redirect_uri == available_redirect_uri_4 ){
      this.state.requestFrom = 'google'
      this.setState({google:true})
      return
    }


    //const user = await Auth.signIn('sayyidyaseen@gmail.com','yaseen1234');
    //console.log('user signing success', JSON.stringify(user));
    try {
      const tokens = await Auth.currentSession();
      //console.log('------------Current user ---------------',JSON.stringify(tokens))
      let userName = tokens.getIdToken().payload['email'];
      userName = userName.trim()
      userName = userName.toLowerCase();
      this.state.email = userName
      // let name = tokens.getIdToken().payload['name'];
      // let email = tokens.getIdToken().payload['email'];
      // let phone_number = tokens.getIdToken().payload['phone_number'];
      console.log('------------Current user ---------------',userName)
      //await Auth.signOut();
      ReactSession.set("username", this.state.email);
      window.open('/devices','_self');
    } catch (error) {
      
    }



  }


  async GetToken(code,redirect_uri){
    axios.post('/token', {
      params: {
        code:code,
        redirect_uri:redirect_uri
      }
    })
    .then(function (response) {
      var code = response.data.body.code
      console.log('Recived data: ',code);
      //var url = redirect_uri+'?code='+code+'&state='+url_state
      //window.open(url,'_self');
    })
  }



  async GetAuthCode(redirect_uri,url_state){
    let RQ_URL = ''
    let AuthCode = ''

    var CLIENT_ID="6lpmrn2815kcqvgrutap0ush6f";
    var CLIENT_SECRET="1n4fvpgesf6ctgukt0mpo5kvovehocteonv0tngnd6i3anphpp0n";
    // var CLIENT_ID="4hr5ambggej4fukgqf7ra6ievi";
    // var CLIENT_SECRET="m5e318odf58l222nv4l3t5390b6md0g9n10ha5lrlo9fcreoqvm";
    var RESPONSE_TYPE="code";
    var REDIRECT_URI= encodeURIComponent("https://aizoteq.com/aizoapp");
    var SCOPE="openid";
    var AUTH_DOMAIN= "aizo.auth.ap-south-1.amazoncognito.com";
    // var USERNAME="sayyidyaseen@gmail.com";
    // var PASSWORD="yaseen1234";
    var CODE_CHALLENGE_METHOD="S256";

    var USERNAME='sayyidyaseen@gmail.com'
    var PASSWORD='yaseen1234';
    //4hr5ambggej4fukgqf7ra6ievi

    //http://localhost:3000/?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fhomegraph&redirect_uri=https%3A%2F%2Fdevelopers.google.com%2Foauthplayground&response_type=code&client_id=6lpmrn2815kcqvgrutap0ush6f&access_type=offline
    
    var csrfRequestUrl = `https://${AUTH_DOMAIN}/oauth2/authorize?response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}`
    //var csrfRequestUrl = `https://aizo.auth.ap-south-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=6lpmrn2815kcqvgrutap0ush6f&redirect_uri=https%3A%2F%2Faizoteq.com%2Faizoapp&scope=openid&code_challenge_method=S256&code_challenge=PxiTu377dz/OBpPmbgoMX1bsQ2TsfKyVOP5cbfkSbO8=`
    // Post CSRF Token and username/password to /login endpoint
    var codeRequestUrl = `https://${AUTH_DOMAIN}/login?response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&redirect_uri=${redirect_uri}`;
    
    console.log('codeRequestUrl-----------',codeRequestUrl)
    let self = this
    // var apiUrl = 'https://xxv54b8qhg.execute-api.ap-south-1.amazonaws.com/v1/authcode?username=sayyidyaseen@gmail.com&password=yaseen1234'
    var apiUrl = 'https://xxv54b8qhg.execute-api.ap-south-1.amazonaws.com/v1/authcode'

    //https://aizo.auth.ap-south-1.amazoncognito.com/login?response_type=code&client_id=6lpmrn2815kcqvgrutap0ush6f&redirect_uri=https://developers.google.com/oauthplayground


    //window.open(csrfRequestUrl,'_self');

    // axios.get('https://auth.aizo.in/test', {
    //   params: {
    //     username: this.state.email,
    //     password:this.state.password,
    //     redirect_uri:redirect_uri
    //   }
    // })
    // .then(function (response) {
    //   console.log('Recived response: ',response);

    // })

    // axios.post('/auth', {
    axios.post('/auth', {
      // headers: {
      //   'x-api-key ': `xj1Pa0BoO04rgXccgUTug9BLmi7WUeOf8MrebIAI` 
      // },
      params: {
        username: this.state.email,
        password:this.state.password,
        redirect_uri:redirect_uri
      }
    })
    .then(function (response) {
      var code = response.data.body.code
      console.log('Recived code: ',code);
      var url = redirect_uri+'?code='+code+'&state='+url_state
      window.open(url,'_self');
    })

    return
    }



    getInitialState () {
      return {
        buttonState: ''
      }
    }

    renderSignIn = ()=>{
      return(
        <form style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }} 
        onSubmit={async (e)=>{
          if(this.state.requestFrom == 'google'){
            this.handleSigninSubmit(e)
          }else{
            this.signIn(e)
          }
          }}
          >
        <img style={{
              width:200,              
            }} src={logo} className="App-logo" alt="logo" />
        {this.state.google?
          <h4 style={{
            color: '#fff' 
             }}>By signing in, you are authorizing Google to control your devices</h4>
            :<div/>}
          <div>
            <input
              id="input1"
              value={this.state.email}
              placeholder='Email'
              type='text'
              onChange={(e)=>this.onChange(e,'email')}
              />
          </div>
          <div>
            <input
              id="input2"
              value={this.state.password}
              placeholder='Password'
              type='password'
              minLength={6}
              onChange={(e)=>this.onChange(e,'password')}
               />
          </div>
          <div style={{}}>
      {!this.state.loading?
      <div>
      <Button  type='submit' variant="primary" style={{ outline: 0, boxShadow: '0px 0px 0px red', backgroundColor:this.state.loading?'black':'white', width:'90%',borderRadius:30,width:250, height:40,margin:10}}>
        <span style={{ fontSize:15}} >Login</span>
      </Button>{' '}
      </div>
      :<div/>}

          <div style={{display:'flex',justifyContent:'center'}}>
          <PuffLoader
            color={'white'}
            loading={this.state.loading}
            cssOverride={true}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>

            <div style={{display:'flex',padding:40}}>
              <span onClick={(e)=>{
                this.setState({mode:'newUser'})
              }}style={{color:'white', fontSize:14}} >New User</span>

              <span onClick={(e)=>{
                this.setState({mode:'forgetPassword'})
              }} style={{color:'white', fontSize:14,display:'flex',flex:1,justifyContent:'flex-end'}} >Forget password</span>
            </div>

          </div>
        </form>
      )
    }

    renderForgetPassword=()=>{
      return(
        <form style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }} 
        onSubmit={async (e)=>{
          this.forgetPassword(e)
          }}
          >
        <img style={{
              width:200,              
            }} src={logo} className="App-logo" alt="logo" />
        {this.state.google?
          <h4 style={{
            color: '#fff' 
             }}>By signing in, you are authorizing Google to control your devices</h4>
            :<div/>}
          <div>
            <input
              id="input1"
              value={this.state.email}
              placeholder='Email'
              type='text'
              onChange={(e)=>this.onChange(e,'email')}
              />
          </div>
          <div style={{}}>
      {!this.state.loading?
      <div>
      <Button  type='submit' variant="primary" style={{ outline: 0, boxShadow: '0px 0px 0px red', backgroundColor:this.state.loading?'black':'white', width:'90%',borderRadius:30,width:250, height:40,margin:10}}>
        <span style={{ fontSize:15}} >Reset</span>
      </Button>{' '}
      </div>
      :<div/>}

          <div style={{display:'flex',justifyContent:'center'}}>
          <PuffLoader
            color={'white'}
            loading={this.state.loading}
            cssOverride={true}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>

            <div style={{display:'flex',padding:40}}>
              <span onClick={(e)=>{
                this.setState({mode:'newUser'})
              }}style={{color:'white', fontSize:14}} >New User</span>

              <span onClick={(e)=>{
                this.setState({mode:'login'})
              }} style={{color:'white', fontSize:14,display:'flex',flex:1,justifyContent:'flex-end'}} >Login</span>
            </div>

          </div>
        </form>
      )
    }

    //
    renderForgetConfirm = ()=>{
      return(
        <form style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }} 
        onSubmit={(e)=>{
          this.forgetConfirm(e)
          }}
          >
        <img style={{
              width:200,              
            }} src={logo} className="App-logo" alt="logo" />
        {this.state.google?
          <h4 style={{
            color: '#fff' 
             }}>By signing in, you are authorizing Google to control your devices</h4>
            :<div/>}
          <span style={{color:'white', fontSize:14}} >Confirmation code has been send to {this.state.email}</span>
          <div>
            <input
              id="input1"
              value={this.state.confirmationCode}
              placeholder='confirmationCode'
              type='text'
              onChange={(e)=>this.onChange(e,'confirmationCode')}
              />
          </div>
          <div>
            <input
              id="input1"
              value={this.state.password}
              placeholder='New password'
              type='password'
              onChange={(e)=>this.onChange(e,'password')}
              />
          </div>
          <div>
            <input
              id="input1"
              value={this.state.password2}
              placeholder='Re-enter new password'
              type='password'
              onChange={(e)=>this.onChange(e,'password2')}
              />
          </div>
          <div style={{}}>
      {!this.state.loading?
      <div>
      <Button  type='submit' variant="primary" style={{ outline: 0, boxShadow: '0px 0px 0px red', backgroundColor:this.state.loading?'black':'white', width:'90%',borderRadius:30,width:250, height:40,margin:10}}>
        <span style={{ fontSize:15}} >Reset</span>
      </Button>{' '}
      </div>
      :<div/>}

          <div style={{display:'flex',justifyContent:'center'}}>
          <PuffLoader
            color={'white'}
            loading={this.state.loading}
            cssOverride={true}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>

            <div style={{display:'flex',padding:40}}>
              <span onClick={(e)=>{
                this.forgetPassword(e)
              }}style={{color:'white', fontSize:14}} >Re-send</span>

              <span onClick={(e)=>{
                this.setState({mode:'login'})
              }} style={{color:'white', fontSize:14,display:'flex',flex:1,justifyContent:'flex-end'}} >Login</span>
            </div>

          </div>
        </form>
      )
    }



    renderNewUser=()=>{
      return(
        <form style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }} 
        onSubmit={ (e)=>{
          this.signUp(e)
          }}
          >
        <img style={{
              width:200,              
            }} src={logo} className="App-logo" alt="logo" />
        {this.state.google?
          <h4 style={{
            color: '#fff' 
             }}>By signing in, you are authorizing Google to control your devices</h4>
            :<div/>}
          <div>
            <input
              id="input1"
              value={this.state.name}
              placeholder='Name'
              type='text'
              onChange={(e)=>this.onChange(e,'name')} />
          </div>
          <div>
            <input
              id="input1"
              value={this.state.email}
              placeholder='Email'
              type='text'
              onChange={(e)=>this.onChange(e,'email')} />
          </div>
          <div style={{}}>
            <input
              id="input1"
              value={this.state.phone}
              placeholder='Phone with county code'
              type='text'
              onChange={(e)=>this.onChange(e,'phone')} />
          {/* <PhoneInput
            defaultCountry="IN"
            placeholder="Enter phone number"
            value={'value'}
            onChange={(value)=>{console.log('value:',value)}}
            //onChange={this.changeEmail}
            /> */}
          </div>
          <div>
            <input
              id="input2"
              value={this.state.password}
              placeholder='Password'
              type='password'
              minLength={6}
              onChange={(e)=>this.onChange(e,'password')} />
          </div>
          <div>
            <input
              id="input2"
              value={this.state.password2}
              placeholder='Re-enter password'
              type='password'
              minLength={6}
              onChange={(e)=>this.onChange(e,'password2')} />
          </div>
          <div style={{}}>
      {!this.state.loading?
      <div>
      <Button  type='submit' variant="primary" style={{ outline: 0, boxShadow: '0px 0px 0px red', backgroundColor:this.state.loading?'black':'white', width:'90%',borderRadius:30,width:250, height:40,margin:10}}>
        <span style={{ fontSize:15}} >Signup</span>
      </Button>{' '}
      </div>
      :<div/>}

          <div style={{display:'flex',justifyContent:'center'}}>
          <PuffLoader
            color={'white'}
            loading={this.state.loading}
            cssOverride={true}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>

            <div style={{display:'flex',padding:40}}>
              <span onClick={(e)=>{
                this.setState({mode:'login'})
              }}style={{color:'white', fontSize:14}} >Login</span>

              <span onClick={(e)=>{
                this.setState({mode:'forgetPassword'})
              }} style={{color:'white', fontSize:14,display:'flex',flex:1,justifyContent:'flex-end'}} >Forget password</span>
            </div>

          </div>
        </form>
      )
    }


    renderConfirmUser = ()=>{
      return(
        <form style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }} 
        onSubmit={ (e)=>{
          this.confirmUser(e)
          }}
          >
        <img style={{
              width:200,              
            }} src={logo} className="App-logo" alt="logo" />
        {this.state.google?
          <h4 style={{
            color: '#fff' 
             }}>By signing in, you are authorizing Google to control your devices</h4>
            :<div/>}
          <span style={{color:'white', fontSize:14}} >Confirmation code has been send to {this.state.email}</span>
          <div>
            <input
              id="input2"
              value={this.state.confirmationCode}
              placeholder='Confirmation code'
              type='number'
              minLength={6}
              onChange={(e)=>this.onChange(e,'confirmationCode')} />
          </div>
          <div style={{}}>
      {!this.state.loading?
      <div>
      <Button  type='submit' variant="primary" style={{ outline: 0, boxShadow: '0px 0px 0px red', backgroundColor:this.state.loading?'black':'white', width:'90%',borderRadius:30,width:250, height:40,margin:10}}>
        <span style={{ fontSize:15}} >Confirm</span>
      </Button>{' '}
      </div>
      :<div/>}

          <div style={{display:'flex',justifyContent:'center'}}>
          <PuffLoader
            color={'white'}
            loading={this.state.loading}
            cssOverride={true}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>

            <div style={{display:'flex',padding:40}}>
              <span onClick={(e)=>{
                this.setState({mode:'login'})
                this.resendCode(null)
              }}style={{color:'white', fontSize:14}} >Re-send code</span>

              <span onClick={(e)=>{
                this.setState({mode:'forgetPassword'})
              }} style={{color:'white', fontSize:14,display:'flex',flex:1,justifyContent:'flex-end'}} >SignIn</span>
            </div>

          </div>
        </form>
      )
    }



  

  render() {
    //console.log('Path:',window.location.href)
    return (
      // <Header />
      
      <div  style={{
        backgroundColor:'#000',
        textAlign:'center',
        position:'absolute',
        flex:0,
        right:0,
        left:0,
        top:0,
        bottom:0
        
        }}  className="Signin" >

          {this.state.mode == 'login'?
          <this.renderSignIn/>
          :this.state.mode == 'forgetPassword'?
          <this.renderForgetPassword/>
          :this.state.mode == 'newUser'?
          <this.renderNewUser/>
          :this.state.mode == 'confirm'?
          <this.renderConfirmUser/>
          :this.state.mode == 'forgetConfirm'?
          <this.renderForgetConfirm/>
          :<div/>
          }
          
          

        {/* <form style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }} 
        onSubmit={async (e)=>{
          if(this.state.requestFrom == 'google'){
            this.handleSigninSubmit(e)
          }else{
            this.signIn(e)
          }
          }}
          >
        <img style={{
              width:200,              
            }} src={logo} className="App-logo" alt="logo" />
        {this.state.google?
          <h4 style={{
            color: '#fff' 
             }}>By signing in, you are authorizing Google to control your devices</h4>
            :<div/>}
          <div>
            <input
              id="input1"
              value={this.state.email}
              placeholder='Email'
              type='text'
              onChange={this.changeEmail} />
          </div>
          <div>
            <input
              id="input2"
              value={this.state.password}
              placeholder='Password'
              type='password'
              minLength={6}
              onChange={this.changePassword} />
          </div>
          <div style={{}}>
      {!this.state.loading?
      <div>
      <Button  type='submit' variant="primary" style={{ outline: 0, boxShadow: '0px 0px 0px red', backgroundColor:this.state.loading?'black':'white', width:'90%',borderRadius:30,width:250, height:40,margin:10}}>
        <span style={{ fontSize:15}} >Login</span>
      </Button>{' '}
      </div>
      :<div/>}

          <div style={{display:'flex',justifyContent:'center'}}>
          <PuffLoader
            color={'white'}
            loading={this.state.loading}
            cssOverride={true}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>

            <div style={{display:'flex',padding:40}}>
              <span onClick={(e)=>{
                this.setState({mode:'newUser'})
              }}style={{color:'white', fontSize:14}} >New User</span>

              <span onClick={(e)=>{
                this.setState({mode:'forgetPassword'})
              }} style={{color:'white', fontSize:14,display:'flex',flex:1,justifyContent:'flex-end'}} >Forget password</span>
            </div>

          </div>
        </form> */}




        <Toaster 
        position="top-center"
        toastOptions={{
          className: '',
          style: {
            border: '1px solid white',
            padding: '16px',
            color: 'white',
            backgroundColor:'gray',
            borderRadius:20,
          },
        }}
        />
      </div>
      
    )
  }
}

export default Signin



