/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:77078c3c-cb35-4651-8d16-9f016c253157",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_MxQOsEU2Y",
    "aws_user_pools_web_client_id": "52ml9t6v1s6esabq5j03vohkl6",
    "oauth": {}
};


export default awsmobile;
