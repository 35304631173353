// import React, { Component } from 'react'
import { Component } from 'react'

import * as React from 'react';

// import { authenticateUser } from "./Cognito"
import { Amplify, Auth, PubSub, Hub } from 'aws-amplify';
// import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { AWSIoTProvider, CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import { SegmentedControl } from 'segmented-control'
import { useRef, useState } from "react";
import styled from "styled-components";

import AppBar from '@mui/material/AppBar';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Globals from './Globals';

import useVH from 'react-viewport-height';



import awsconfig from '../aws-exports';
import './styles.css';
// import fetchTimeout from 'fetch-timeout';
import axios from "axios";
// import Cookies from 'js-cookie';
//import cookie from 'react-cookie';
// import fetch from 'node-fetch';
// import Signup from './Signup'
// import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Sidebar from "react-sidebar";
import { ProSidebarProvider } from 'react-pro-sidebar';
import ButtonGroup from '@mui/material/ButtonGroup';



import './Devices.css';

import {
  Grid,
  Card,
  // CardContent,
  // Typography,
  // CardHeader
} from '@material-ui/core/'

import FlatList from 'flatlist-react';
import { borderRadius, margin } from '@mui/system';
import { fontWeight } from '@mui/joy/styles/styleFunctionSx';




//const mql = window.matchMedia(`(min-width: 800px)`);


Amplify.configure(awsconfig);

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  },
  Analytics: {
    disabled: true,
  }
});

try {
  console.log('addPluggable==========>')
  Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_REGION,
    aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
  }));
} catch (error) {
  console.log('MQTT Connect Error==========>',error)
  // Auth.currentCredentials().then((info) => {
//   const cognitoIdentityId = info.identityId;
//   console.log('cognitoIdentityId==========>',cognitoIdentityId)
//   //aws iot attach-policy --policy-name 'myIoTPolicy' --target '<YOUR_COGNITO_IDENTITY_ID>'
//   //aws iot attach-policy --policy-name 'thing_policy' --target 'ap-south-1:db114daa-b6c2-4bd9-99ba-9c20d3d91fcf'
//   //aws iot attach-policy --policy-name 'thing_policy' --target 'ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d'
//aws iot attach-policy --policy-name 'thing_policy' --target 'ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d'
// aws iot detach-policy --policy-name 'thing_policy' --target 'ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d'
  
// });
//https://x7gwnad4hj.execute-api.ap-south-1.amazonaws.com/v1/attach?cognitoIdentityId=ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d

}

//


// Hub.listen('pubsub', (data) => {
//   const { payload } = data;
//   if (payload.event === CONNECTION_STATE_CHANGE) {
//     const connectionState = payload.data.connectionState;
//     console.log('connectionState====>', connectionState);
//     // if(connectionState == 'Connected'){
//     //   this.state.thingNames.forEach(element => {
//     //     this.SUBSCRIBE(element)
//     //   });
//     // }

//   }
// });








// Auth.currentCredentials().then((info) => {
//   const cognitoIdentityId = info.identityId;
//   console.log('cognitoIdentityId==========>',cognitoIdentityId)
//   //aws iot attach-policy --policy-name 'myIoTPolicy' --target '<YOUR_COGNITO_IDENTITY_ID>'
//   //aws iot attach-policy --policy-name 'thing_policy' --target 'ap-south-1:db114daa-b6c2-4bd9-99ba-9c20d3d91fcf'
//   //aws iot attach-policy --policy-name 'thing_policy' --target 'ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d'
  
// });
//https://x7gwnad4hj.execute-api.ap-south-1.amazonaws.com/v1/attach?cognitoIdentityId=ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d


class Signin extends Component {
  constructor(props) {
    super(props)

    this.render_device = this.render_device.bind(this)
    this.thingCreater = this.thingCreater.bind(this)
    this.SUBSCRIBE = this.SUBSCRIBE.bind(this)
    this.PUBLISH = this.PUBLISH.bind(this)
    this.HANDLE_MESSAGE = this.HANDLE_MESSAGE.bind(this)
    this.HANDLE_CLICK = this.HANDLE_CLICK.bind(this)
    this.setSpeed = this.setSpeed.bind(this)
    this.ToggleGroup = this.ToggleGroup.bind(this)
    this.username = ''
    this.phone = ''
    this.email = ''

    this.images = {
      'Fan_rotate': require('../assets/fan.png'),
      'Fan3_rotate': require('../assets/fan3.png'),
      'Fan4_rotate': require('../assets/fan4.png'),
      'Fan5_rotate': require('../assets/fan5_rotate.png'),
      'Fan5': require('../assets/fan5.png'),
      'Bulb': require('../assets/bulb.png'),
      'Bulb1': require('../assets/bulb1.png'),
      'Bulb2': require('../assets/bulb2.png'),
      'Wall Light': require('../assets/wall_light.png'),
      'Wall Light1': require('../assets/wall_light1.png'),
      'Spot Light': require('../assets/spot_light.png'),
      'Hanging Lamp': require('../assets/hanging_lamp.png'),
      'Curtain Light': require('../assets/curtain_light.png'),
      'Stage Light': require('../assets/stage_light.png'),
      'Sconcs Light': require('../assets/sconce_light.png'),
      'Table Fan': require('../assets/table_fan1.png'),
      'Table Fan_rotate': require('../assets/table_fan1_rotate.png'),
      //'Ceiling Fan': require('../assets/ceiling_fan1.png'),
      'Ceiling Fan_rotate': require('../assets/ceiling_fan1.png'),
      'Fan Exhoust_rotate': require('../assets/fan2.png'),
      'Power Switch': require('../assets/power.png'),
      'Add': require('../assets/add.png'),
      'Refresh': require('../assets/refresh.png'),
      'Info': require('../assets/information.png'),
      'Socket': require('../assets/socket.png'),
      'Socket1': require('../assets/socket1.png'),
      'Socket2': require('../assets/socket2.png'),
      'Split AC': require('../assets/split_ac.png'),
      'AC': require('../assets/ac2.png'),
      // 'Table Fan': require('../assets/table_fan.png'),
      'Washing Machine': require('../assets/washing_machine.png'),
      'Table Lamp': require('../assets/table_lamp.png'),
      'Charger': require('../assets/charger.png'),
      'Phone': require('../assets/phone.png'),
      'Lock': require('../assets/lock.png'),
      'Water Heater': require('../assets/water_heater.png'),
      'User': require('../assets/user.png'),
      'TV': require('../assets/tv.png'),
      'Speaker': require('../assets/speaker.png'),
      'Siren': require('../assets/siren.png'),
      'Water Pump': require('../assets/water_pump.png'),
      'Ceiling Light': require('../assets/ceiling_light.png'),
      'Ceiling Light1': require('../assets/ceiling_light1.png'),
      'Oven': require('../assets/oven.png'),
      'Printer': require('../assets/printer.png'),
      'Desk Lamp': require('../assets/desk_lamp.png'),
      'Desk Lamp1': require('../assets/desk_lamp1.png'),
      'Desk Lamp2': require('../assets/desk_lamp2.png'),
      'Desk Lamp2': require('../assets/desk_lamp2.png'),
      'Dining Light': require('../assets/dining_light.png'),

      'Mixer': require('../assets/mixer.png'),
      'PC': require('../assets/pc.png'),
      'Hoock Light': require('../assets/hoock_light.png'),
      'Coffee Maker': require('../assets/coffee_maker.png'),
      // 'Gate2': require('../assets/gate.png'),
      // 'Gate3': require('../assets/gate1.png'),
      'Garage': require('../assets/garage.png'),
      'Toilet': require('../assets/toilet.png'),
      'CFL': require('../assets/cfl.png'),
      'LED Tube': require('../assets/led_tube.png'),
      'Tube': require('../assets/tube.png'),
      'Tube1': require('../assets/tube1.png'),
      'Kitchen Light': require('../assets/kitchen_light.png'),
      'Dimmer': require('../assets/dimmer.png'),
      'Dish washer': require('../assets/dish_washer.png'),
      'Iron Box': require('../assets/iron_box.png'),
      'Router': require('../assets/router.png'),
      'Delete': require('../assets/delete.png'),
      'Edit': require('../assets/edit.png'),
      'Eye': require('../assets/eye.png'),
      'Noon': require('../assets/noon.png'),
      'Cofee': require('../assets/cofee.png'),
      'Sunset': require('../assets/sunset.png'),
      'Home': require('../assets/home.png'),
      'Afternoon': require('../assets/afternoon.png'),
      'Night': require('../assets/night.png'),
      'Secure': require('../assets/secure.png'),
      'Safe': require('../assets/safe.png'),
      'Sleep': require('../assets/sleep.png'),
      'Flight': require('../assets/flight.png'),
      'Movie': require('../assets/movie.png'),
      'Warning': require('../assets/warning.png'),
      'Welcome': require('../assets/welcome.png'),
      'Arrow_up': require('../assets/arrow_up.png'),
      'Twoway': require('../assets/twoway.png'),
      'Log': require('../assets/log.png'),
      'Filter': require('../assets/filter.png'),
      'Dot': require('../assets/dot.png'),
      'Computer': require('../assets/computer.png'),
      'Flapper': require('../assets/flapper.png'),
      'Switch': require('../assets/switch.png'),
      'Bell': require('../assets/bell.png'),
      'Alexa': require('../assets/alexa.png'),
      'Google Assistant': require('../assets/google-assistant.png'),
      'Apple Homekit': require('../assets/apple-homekit.png'),
      'RGB Bulb': require('../assets/rgb_bulb.png'),
      'Gate': require('../assets/gate_close.png'),
      'Gate_open': require('../assets/gate_open.png'),
      'Gate_close': require('../assets/gate_close.png'),
      'Wifi_low': require('../assets/wifi_low.png'),
      'Wifi_med': require('../assets/wifi_med.png'),
      'Wifi_high': require('../assets/wifi_high.png'),
      'Wifi_ultra': require('../assets/wifi_ultra.png'),
      'Rgb': require('../assets/rgb.png'),
      'Energymeter': require('../assets/energy_meter.png'),
    }


    this.state = {

      xPos: "0px",
      yPos: "0px",
      showMenu: false,
      email: '',
      password: '',
      loading: false,
      redirectUri: '',
      url_state: '',
      arrayHolder: [],
      things: {},
      rooms: {},
      files: {},
      thingNames: [],
      sidebarOpen: false,
      sidebarDocked: false,
      slected:'flex-end',
      fanRegulator:false,
      current_index:0,
      current_value:0,
      current_state:0,
      current_thingName:'',
      current_id:'',
      current_device:'ESP32',
      segment_index:0,
      config_window:false,
      subscibed:false,
      mqttConnected:true,
      light:0,
      fan:0,
      socket:0,
      curtain:0,
      dimmer:0,
      h_relay:0,
      gate:0,
      bell:0,
      rgb:0,
      type:0,
      wf:2,
      swct:1,
      sw1:0,
      sw2:0,
      sw3:0,
      sw4:0,
      rl1:0,
      rl2:0,
      rl3:0,
      rl4:0,
      th1:0,
      th2:0,
      th3:0,
      th4:0,
      th5:0,
      th6:0,
      th7:0,
      th8:0,
      th9:0,
      th10:0,
      map1:0,
      map2:0,
      hardware_id:'',
      deviceFile:{},
      pinTestFile:{},
      current_type_label:'',
      modules_inputs:[{}],
      touch_valus:[{}],
      module_types:[
        {label:'MICROCHIP BASED',value:0}, 
        {label:'SWITCH MODULE',value:1},
        {label:'RGBWC',value:2},
        {label:'RGB',value:3},
        {label:'WC',value:4},
        {label:'GATE_SWITCH',value:5}
      ],

      fanOptions:[
        { label: "OFF", value: "OFF",default:true },
        { label: "LOW", value: "LOW"},
        { label: "MED", value: "MED" },
        { label: "HIGH", value: "HIGH"},
        { label: "TURBO", value: "TURBO" }
      ]
    }
  }







  HANDLE_CLICK = (thingName, index, s, v) => {
    let S = 'on'
    if (s == 1) {
      S = 'on'
    } else {
      S = 'off'
    }
    let message = {
      state: {
        desired: {
          command: "power",
          c: (index + 1),
          s: S,
          v: v,
          u: "Yaseen"
        }
      }
    }
    this.PUBLISH(thingName, message)
  }


  SUBSCRIBE = async (thingName) => {
    if(!this.state.mqttConnected){return}
    try {
      //console.log('subscribing==========>', thingName)
      let topic = '$aws/things/' + thingName + '/shadow/update'
      await Amplify.PubSub.subscribe(topic).subscribe({
        next: data => {
          //console.log('Message received', JSON.stringify(data.value))
          this.HANDLE_MESSAGE(thingName, data.value)
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      });
      let message = { state: { desired: { command: "get_state" } } }
      this.PUBLISH(thingName, message)
      setTimeout(() => {
        message = { state: { desired: { command: "get_touch_time" } } }
        this.PUBLISH(thingName, message)
      }, 1000);
    } catch (error) {
      console.log('Error subscribing==========>', error)
    }

  }

  PUBLISH = async (thingName, messege) => {
    if(!this.state.mqttConnected){return}
    let topic = '$aws/things/' + thingName
    await PubSub.publish(topic, messege);
  }

  HANDLE_MESSAGE = (thingName, message) => {
    let command = message.state.desired.command
    let C, S, V, key
    if (command == 'power') {
      thingName = message.state.desired.id
      C = message.state.desired.c
      S = message.state.desired.s
      V = message.state.desired.v
      key = thingName + '_S' + C
      this.state.files[key] = S
      key = thingName + '_V' + C
      this.state.files[key] = V
      this.setState({ files: this.state.files })
      if(V != 254){
        this.state.current_value = V
        if(V == 0 || S == 0){
          console.log('setting OFF')
          this.setSpeed('OFF')
        }else if(V > 0 && V <= 25){
          console.log('setting LOW')
          this.setSpeed('LOW')
        }else if(V > 25  && V <= 50){
          console.log('setting MED')
          this.setSpeed('MED')
        }else if(V > 50  && V <= 75){
          console.log('setting HIGH')
          this.setSpeed('HIGH')
        }else if(V > 75  && V <= 100){
          console.log('setting TURBO')
          this.setSpeed('TURBO')
        }
      }
    }
    else if (command == 'status') {
      let MS = message.state.desired.deviceState
      let len = MS.length / 3
      for (let j = 0; j < len; j++) {
        let G = j * 3
        C = MS[G]
        S = MS[(G + 1)]
        V = MS[(G + 2)]
        key = thingName + '_S' + C
        this.state.files[key] = S
        key = thingName + '_V' + C
        this.state.files[key] = V
      }
      this.setState({ files: this.state.files })
    }else if (command == 'pins') {
      let data = message.state.desired.data
      this.state.hardware_id = message.state.desired.hardware_id
      this.state.light = data.light
      this.state.fan = data.fan
      this.state.socket = data.socket
      this.state.curtain = data.curtain
      this.state.dimmer = data.dimmer
      this.state.h_relay = data.h_relay
      this.state.gate = data.gate
      this.state.bell = data.bell
      this.state.rgb = data.rgb
      this.state.type = data.type
      this.state.wf = data.wf
      this.state.swct = data.swct
      this.state.swct = data.swct
      this.state.sw1 = data.sw1
      this.state.sw2 = data.sw2
      this.state.sw3 = data.sw3
      this.state.sw4 = data.sw4
      this.state.rl1 = data.rl1
      this.state.rl2 = data.rl2
      this.state.rl3 = data.rl3
      this.state.rl4 = data.rl4

      if(this.state.hardware_id == '1'){
        this.state.current_device = 'ESP8266'
        sessionStorage.setItem("current_device", this.state.current_device);
      }else if(this.state.hardware_id == '2'){
        this.state.current_device = 'ESP32'
        sessionStorage.setItem("current_device", this.state.current_device);
      }

      if(this.state.bell == 255 || this.state.bell == 0)
        {this.state.bell = 0}
      else{this.state.bell = 1}
      let total_devices = parseInt(this.state.light)+parseInt(this.state.socket)+parseInt(this.state.bell)+parseInt(this.state.h_relay)
      if(this.state.fan != '0'){total_devices = total_devices+3}
      if(this.state.gate == '1' || this.state.curtain == '1'){total_devices = total_devices+3}
      this.state.touch_valus = []
      total_devices = 10
      for(let i=0;i<total_devices;i++){
        this.state.touch_valus.push({})
      }
      
      this.state.current_type_label = this.state.module_types[parseInt(this.state.type)]
      this.state.current_type  = this.state.type
      this.state.modules_inputs = []
      for(let i=0;i<parseInt(this.state.swct);i++){
        this.state.modules_inputs.push({})
      }
      console.log('this.state.modules_inputs: ',JSON.stringify(this.state.modules_inputs))
      this.setState({light:this.state.light})
    }else if (command == 'touchTime') {
      let touch = message.state.desired.touch
      for(let i=0;i<10;i++){
        let key = 'th'+(i+1)
        this.state[key] = touch[i]
      }
      this.setState({state:this.state})
    }else if (command == 'map') {
      this.state.map1= message.state.desired['map1']
      this.state.map2 = message.state.desired['map2']
      this.setState({state:this.state})
    }

    
  }

  thingCreater(data) {
    //console.log('Recived data: ',JSON.stringify(data));
    this.state.arrayHolder = []
    let things = {}
    let rooms = {}
    data.forEach(element => {
      //console.log('data===>: ',element.thing_data);
      let deviceinfo = JSON.parse(element.thing_data)
      // console.log('light: ',deviceinfo.light);
      // console.log('fan: ',deviceinfo.fan);
      // console.log('socket: ',deviceinfo.socket);
      // console.log('curtain: ',deviceinfo.curtain);
      // console.log('bell: ',deviceinfo.bell);
      // console.log('gate: ',deviceinfo.gate);
      // console.log('rgb: ',deviceinfo.rgb);
      // console.log('switch: ',deviceinfo.switch);
      let no_of_light = deviceinfo.light
      let no_of_fan = deviceinfo.fan
      let no_of_socket = deviceinfo.socket
      let no_of_curtain = deviceinfo.curtain
      let no_of_switch = deviceinfo.switch
      let no_of_gate = deviceinfo.gate
      let no_of_bell = deviceinfo.bell
      let no_of_dimmer = deviceinfo.dimmer
      let no_of_rgb = deviceinfo.rgb

      let array = []
      let C = 1
      let name = ''
      let name_key = ''
      let icon = ''
      let type = ''
      let icon_key = ''
      let type_key = ''
      let cb_key = ''
      let local = '0'
      let thingName = deviceinfo.thingName
      let DATA = deviceinfo
      let id = element.id
      let cb = 1
      let room_name = deviceinfo.room
      this.state.thingNames.push(thingName)
      this.SUBSCRIBE(thingName)

      for (let i = 0; i < no_of_fan; i++) {
        //console.log('fan i--------------',i)
        name_key = 'name_' + C
        name = DATA[name_key]
        icon_key = 'icon_' + C
        icon = DATA[icon_key]
        type_key = 'type_' + C
        type = DATA[type_key]
        cb_key = 'cb_' + C
        cb = DATA[cb_key]
        array.push({ name: name, room: room_name, type: type, icon: icon, thingName: thingName, status: 0, id: id, cb: cb, local: local });
        C++
      }
      for (let i = 0; i < no_of_light; i++) {
        //console.log('Light i--------------',i)
        name_key = 'name_' + C
        name = DATA[name_key]
        icon_key = 'icon_' + C
        icon = DATA[icon_key]
        type_key = 'type_' + C
        type = DATA[type_key]
        cb_key = 'cb_' + C
        cb = DATA[cb_key]
        array.push({ name: name, room: room_name, type: type, icon: icon, thingName: thingName, status: 0, id: id, cb: cb, local: local });
        C++
      }
      for (let i = 0; i < no_of_curtain; i++) {
        //console.log('Curtain i--------------',i)
        name_key = 'name_' + C
        name = DATA[name_key]
        icon_key = 'icon_' + C
        icon = DATA[icon_key]
        type_key = 'type_' + C
        type = DATA[type_key]
        cb_key = 'cb_' + C
        cb = DATA[cb_key]
        array.push({ name: name, room: room_name, type: type, icon: icon, thingName: thingName, status: 0, id: id, cb: cb, local: local });
        C++
      }
      for (let i = 0; i < no_of_socket; i++) {
        //console.log('Socket i--------------',i)
        name_key = 'name_' + C
        name = DATA[name_key]
        icon_key = 'icon_' + C
        icon = DATA[icon_key]
        type_key = 'type_' + C
        type = DATA[type_key]
        cb_key = 'cb_' + C
        cb = DATA[cb_key]
        array.push({ name: name, room: room_name, type: type, icon: icon, thingName: thingName, status: 0, id: id, cb: cb, local: local });
        C++
        //console.log('Socket i--------------',i)
      }
      for (let i = 0; i < no_of_switch; i++) {
        //console.log('Socket i--------------',i)
        name_key = 'name_' + C
        name = DATA[name_key]
        icon_key = 'icon_' + C
        icon = DATA[icon_key]
        type_key = 'type_' + C
        type = DATA[type_key]
        cb_key = 'cb_' + C
        cb = DATA[cb_key]
        array.push({ name: name, room: room_name, type: type, icon: icon, thingName: thingName, status: 0, id: id, cb: cb, local: local });
        C++
        //console.log('Socket i--------------',i)
      }
      for (let i = 0; i < no_of_gate; i++) {
        //console.log('Socket i--------------',i)
        name_key = 'name_' + C
        name = DATA[name_key]
        icon_key = 'icon_' + C
        icon = DATA[icon_key]
        type_key = 'type_' + C
        type = DATA[type_key]
        cb_key = 'cb_' + C
        cb = DATA[cb_key]
        array.push({ name: name, room: room_name, type: type, icon: icon, thingName: thingName, status: 0, id: id, cb: cb, local: local });
        C++
        //console.log('Socket i--------------',i)
      }
      // if(no_of_bell != '255')
      if ((no_of_bell > 0) && (no_of_bell < 10)) {
        // for(let i=0;i<no_of_switch;i++){
        //console.log('Socket i--------------',i)
        name_key = 'name_' + C
        name = DATA[name_key]
        icon_key = 'icon_' + C
        icon = DATA[icon_key]
        type_key = 'type_' + C
        type = DATA[type_key]
        cb_key = 'cb_' + C
        cb = DATA[cb_key]
        array.push({ name: name, room: room_name, type: type, icon: icon, thingName: thingName, status: 0, id: id, cb: cb, local: local });
        C++
        //console.log('Socket i--------------',i)
        // }
      }
      for (let i = 0; i < no_of_dimmer; i++) {
        //console.log('Socket i--------------',i)
        name_key = 'name_' + C
        name = DATA[name_key]
        icon_key = 'icon_' + C
        icon = DATA[icon_key]
        type_key = 'type_' + C
        type = DATA[type_key]
        cb_key = 'cb_' + C
        cb = DATA[cb_key]
        array.push({ name: name, room: room_name, type: type, icon: icon, thingName: thingName, status: 0, id: id, cb: cb, local: local });
        C++
      }

      for (let i = 0; i < no_of_rgb; i++) {
        //console.log('RGB i--------------',i)
        name_key = 'name_' + C
        name = DATA[name_key]
        icon_key = 'icon_' + C
        icon = DATA[icon_key]
        type_key = 'type_' + C
        type = DATA[type_key]
        cb_key = 'cb_' + C
        cb = DATA[cb_key]
        // console.log('RGB name--------------',name)
        // console.log('RGB icon--------------',icon)
        // console.log('RGB type--------------',type)
        array.push({ name: name, room: room_name, type: type, icon: icon, thingName: thingName, status: 0, id: id, cb: cb, local: local });
        C++
      }
      things[thingName] = array
      this.state.arrayHolder.push({ [room_name]: array })

    });
    this.state.things = things
    this.state.files['84F70352C5AC_S1'] = 1
    this.setState({ arrayHolder: this.state.arrayHolder })

    //console.log('this.state.things--------------',JSON.stringify(this.state.things))

  }



  mouseDetect = () => {
    if(this.state.showMenu){
      this.setState({
        showMenu: false,
      });
    }
  }

  componentWillUnmount() {
    //document.removeEventListener("click", this.handleRightClick);
    //document.removeEventListener("contextmenu", this.handleContextMenu);
  }


  async load_devices(){
    try {
      const tokens = await Auth.currentSession();
      //console.log('------------Current user ---------------',JSON.stringify(tokens))
      let userName = tokens.getIdToken().payload['email'];
      userName = userName.trim()
      userName = userName.toLowerCase();
      this.state.email = userName
      // let name = tokens.getIdToken().payload['name'];
      this.username = tokens.getIdToken().payload['name'];
      this.email = tokens.getIdToken().payload['email'];
      this.phone = tokens.getIdToken().payload['phone_number'];
      console.log('------------Current user Devices---------------', userName)
        let url = 'https://iot.aizoteq.com/list-secure';
        if(Globals.LOCAL_TEST){
          url = Globals.LOCAL_HOST+'/list-secure';
        }else{
          url = 'https://iot.aizoteq.com/list-secure';
        }
      axios.get(url, {
        params: {
          user_name: this.state.email,
          api_key: 'xj1Pa0BoO04rgXccgUTug9BLmi7WUeOf8MrebIAI',
          data_type: 'thing'
        }
      })
        .then((response) => {
          let data = response.data.body
          //console.log('data====> ', JSON.stringify(data));
          this.thingCreater(data)
        })

    } catch (error) {
      console.log('Recived error: ', error);
      window.open('/','_self');
    }
  }

  async componentDidMount() {

     //document.addEventListener("click", this.handleRightClick);
    //document.addEventListener("contextmenu", this.handleContextMenu);

    window.addEventListener("click", this.mouseDetect)
  

    console.log('props:', this.props.agent)
    document.title = "AiZo"
    this.state.current_thingName  = sessionStorage.getItem("current_thingName");
    this.state.current_device  = sessionStorage.getItem("current_device");
    this.state.config_window  = sessionStorage.getItem("config_window");

    Hub.listen('pubsub', (data) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState;
        console.log('connectionState====>', connectionState);
        if(connectionState === 'Connected'){
          this.setState({mqttConnected:true})
        }else if(connectionState === 'Disconnected'){
            Auth.currentCredentials().then((info) => {
              const cognitoIdentityId = info.identityId;
              console.log('cognitoIdentityId==========>',cognitoIdentityId)
              let url = 'https://iot.aizoteq.com/attachpolicy';
              if(Globals.LOCAL_TEST){
                url = Globals.LOCAL_HOST+'/attachpolicy';
              }else{
                url = 'https://iot.aizoteq.com/attachpolicy';
              }
              axios.get(url, {
                params: {
                  cognitoIdentityId: cognitoIdentityId,
                  api_key: 'xj1Pa0BoO04rgXccgUTug9BLmi7WUeOf8MrebIAI',
                }
              })
                .then((response) => {
                  //let data = response.data.body
                  //console.log('axios data====> ', JSON.stringify(data));
                  //console.log('axios response====> ', JSON.stringify(response));
                })
              
            });
            //https://x7gwnad4hj.execute-api.ap-south-1.amazonaws.com/v1/attach?cognitoIdentityId=ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d
            //https://x7gwnad4hj.execute-api.ap-south-1.amazonaws.com/v1/attachpolicy?cognitoIdentityId=ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d
        
          }
        else{
          this.setState({mqttConnected:true})
        }
        
        // if(connectionState == 'Connected'){
        //   this.state.thingNames.forEach(element => {
        //     this.SUBSCRIBE(element)
        //   });
        // }
    
      }
    });
    

    

    try {
      const tokens = await Auth.currentSession();
      //console.log('------------Current user ---------------',JSON.stringify(tokens))
      let userName = tokens.getIdToken().payload['email'];
      userName = userName.trim()
      userName = userName.toLowerCase();
      this.state.email = userName
      // let name = tokens.getIdToken().payload['name'];
      this.username = tokens.getIdToken().payload['name'];
      this.email = tokens.getIdToken().payload['email'];
      this.phone = tokens.getIdToken().payload['phone_number'];
      console.log('------------Current user Devices---------------', userName)
        let url = 'https://iot.aizoteq.com/list-secure';
        if(Globals.LOCAL_TEST){
          url = Globals.LOCAL_HOST+'/list-secure';
        }else{
          url = 'https://iot.aizoteq.com/list-secure';
        }
      axios.get(url, {
        params: {
          user_name: this.state.email,
          api_key: 'xj1Pa0BoO04rgXccgUTug9BLmi7WUeOf8MrebIAI',
          data_type: 'thing'
        }
      })
        .then((response) => {
          let data = response.data.body
          //console.log('data====> ', JSON.stringify(data));
          this.thingCreater(data)
        })

    } catch (error) {
      console.log('Recived error: ', error);
      window.open('/','_self');
    }






  }

  PIN_TEST = (thingName,pin)=>{
    console.log('thingName:',thingName)
    console.log('pin:',pin)
    this.state.pinTestFile = {
      "state": {
        "desired": {
          "command": "pintest",
          "pin": pin
        }
      }
    }
    this.PUBLISH(thingName, this.state.pinTestFile)
    console.log(JSON.stringify(this.state.pinTestFile))
  }

  render_esp_config = ()=>{

  }

  render_device_pins = (thingName, idx) => {
    let room = this.state.current_device
    thingName = '483FDA6F8681'
    let data = []
    const options = [
      'ESP8266', 'ESP32'
    ];
    // const options2 = [
    //   'MICROCHIP BASED', 'SWITCH MODULE','RGBWC','RGB','WC','GATE_SWITCH'
    // ]
    const options2 = [
      {label:'MICROCHIP BASED',value:0}, 
      {label:'SWITCH MODULE',value:1},
      {label:'RGBWC',value:2},
      {label:'RGB',value:3},
      {label:'WC',value:4},
      {label:'GATE_SWITCH',value:5}
    ]
    const options3 = [
      {label:'1',value:1}, 
      {label:'2',value:2},
      {label:'3',value:3},
      {label:'4',value:4}
    ]
    const defaultOption = options[0];
    const defaultOption2 = options2[0];
    if(room == 'ESP32'){
    data = [
      {name:'Pin',pin:0},
      {name:'Pin',pin:1},
      {name:'Pin',pin:3},
      {name:'Pin',pin:4},
      {name:'Pin',pin:5},
      {name:'Pin',pin:6},
      {name:'Pin',pin:7},
      {name:'Pin',pin:9},
      {name:'Pin',pin:10},
      {name:'Pin',pin:18},
      {name:'Pin',pin:19}
    ]
  }else if(room == 'ESP8266'){
    data = [
      {name:'Pin',pin:0},
      {name:'Pin',pin:1},
      {name:'Pin',pin:2},
      {name:'Pin',pin:3},
      {name:'Pin',pin:4},
      {name:'Pin',pin:5},
      {name:'Pin',pin:12},
      {name:'Pin',pin:13},
      {name:'Pin',pin:14},
      {name:'Pin',pin:15},
      {name:'Pin',pin:16}
    ]
  }
  const device_types = [
    {name:'Light',key:'light'},
    {name:'Fan',key:'fan'},
    {name:'Socket',key:'socket'},
    {name:'Curtain',key:'curtain'},
    {name:'Switch',key:'h_relay'},
    {name:'Gate',key:'gate'},
    {name:'Bell',key:'bell'},
    {name:'RGB',key:'rgb'},
    {name:'WIFI pin',key:'wf'},
  ]
  const modules_inputs = [
    {name:'SW',key:'1'},
    {name:'SW',key:'2'},
    {name:'SW',key:'3'},
    {name:'SW',key:'4'}
  ]



    return (
      <div key={room + idx.toString()} style={{display:'flex',
        flexDirection:'column',marginLeft:-25}}>
        {/* <div className="room_name">{room} - {thingName}</div> */}
        <div style={{display:'flex',flexDirection:'row'}}>
        <Dropdown options={options} onChange={(e)=>{
          console.log(e.value)
          sessionStorage.setItem("current_device", e.value);
          this.setState({current_device:e.value})
          }} 
          value={this.state.current_device} 
          placeholder="Select device" />;
          <form style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <input id='thing' placeholder='Thing name' style={{paddingLeft:5, height:38 ,borderRadius:3}} type="text"
              //value={this.state.current_thingName }
              defaultValue={this.state.current_thingName}
              onChange={(e)=>{
                this.state.current_thingName = e.target.value
                sessionStorage.setItem("current_thingName", e.target.value);
                }}/>
          </form>
            <Button style={{marginLeft:10, backgroundColor:this.state.subscibed?'green':'red', borderRadius:10, borderWidth:2,borderColor:'white', color:'white'}}
              onClick={() => {
                if(this.state.subscibed){
                  this.setState({subscibed:false})
                }else{
                  this.setState({subscibed:true})
                  this.SUBSCRIBE(this.state.current_thingName)
                  let file = {"state": {"desired": {"command": "get_pins"}}}
                  this.PUBLISH(this.state.current_thingName,file)
                  setTimeout(() => {
                    let message = { state: { desired: { command: "get_touch_time" } } }
                    this.PUBLISH(this.state.current_thingName, message)
                  }, 100);
                  setTimeout(() => {
                    let message = { state: { desired: { command: "get_map" } } }
                    this.PUBLISH(this.state.current_thingName, message)
                  }, 200);
                }
              }}
            > {this.state.subscibed?'Disconnect':'Connect'}
            </Button>
            <Button style={{marginLeft:10, backgroundColor:'gray', borderRadius:10, borderWidth:2,borderColor:'white', color:'white'}}
              onClick={() => {
                let file = {"state": {"desired": {"command": "get_pins"}}}
                this.PUBLISH(this.state.current_thingName,file)
                setTimeout(() => {
                  let message = { state: { desired: { command: "get_touch_time" } } }
                  this.PUBLISH(this.state.current_thingName, message)
                }, 100);
                setTimeout(() => {
                  let message = { state: { desired: { command: "get_map" } } }
                  this.PUBLISH(this.state.current_thingName, message)
                }, 200);
              }}
              
            > Read 
            </Button>
        </div>
        <Grid container spacing={0} style={{}}>
          {Array.from(data).map((item, index) => (
            <Grid key={room + index.toString()} item xs={'auto'} >
              <Card variant="outlined" className="card" style={Styles.card_black}
                onClick={() => {
                  console.log('Sending pintest message')
                  this.PIN_TEST(this.state.current_thingName,item.pin)
                }}
              >
                <div className="item" >
                  <div className="icon_container">
                      <img className={'image_black'} src={this.images['Bulb']} alt='scsa'></img>
                  </div>
                  <div style={Styles.white}>{item.name+' '+item.pin}</div>
                  {/* <div style={Styles.black}>{'on'}</div> */}
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={0} style={{}}>

        {Array.from(device_types).map((item, index) => (
          <label style={{display:'flex',flexDirection:'row',color:'white',justifyContent:'center',alignItems:'center', margin:10}}>
          {item.name}
          <input 
            style={{height:38,borderRadius:10,marginLeft:5}} 
            min={0}
            max={item.key == 'fan'?1:item.key == 'gate'?1:item.key == 'curtain'?1:item.key == 'rgb'?1:item.key == 'bell'?1:10}
            type="number" 
            value={this.state[item.key]}
            onChange={(e)=>{
              this.setState({[item.key]:e.target.value})
              //console.log('Value changed ',e.target.value)
            }}
          />
          </label>
        ))}
        <label style={{display:'flex',flexDirection:'row',color:'white',justifyContent:'center',alignItems:'center', margin:10}}> 
          Device type  
          <Dropdown 
            options={this.state.module_types} 
            onChange={(e)=>{
            console.log(e)
            this.state.current_type_label = e.label
            this.state.type = e.value
            this.setState({current_type:e.value})
            }} value={this.state.current_type_label} 
            placeholder="Select type"/>
        </label>
        {this.state.current_type == '1'?
        <label style={{display:'flex',flexDirection:'row',color:'white',justifyContent:'center',alignItems:'center', margin:10}}> 
          No of I/O  
          <Dropdown options={options3} 
            onChange={(e)=>{
            console.log(e)
            this.state.swct = e.value
            this.state.modules_inputs = []
            for(let i=0;i<parseInt(this.state.swct);i++){
              this.state.modules_inputs.push({})
            }
            this.setState({modules_inputs:this.state.modules_inputs})
            }} value={options3[parseInt(this.state.swct)-1]} 
            placeholder="Select type"/>
        </label>
        :<div/>}  

        </Grid>    
        {this.state.current_type == '1'?
        <Grid container spacing={0} style={{}}>
          {Array.from(this.state.modules_inputs).map((item, index) => (
            <label style={{display:'flex',flexDirection:'row',color:'white',justifyContent:'center',alignItems:'center', margin:10}}>
            {'SW '+(index+1)}
            <input 
              style={{height:38,borderRadius:10,marginLeft:5}} 
              min={0}
              max={20}
              type="number" 
              value={this.state['sw'+(index+1)]}
              onChange={(e)=>{
                let key = 'sw'+(index+1)
                this.setState({[key]:(index+1)})
                console.log('Value changed ',(index+1))
              }}
            />
            <label style={{display:'flex',flexDirection:'row',color:'white',justifyContent:'center',alignItems:'center', margin:10}}>
            {'RL '+(index+1)}
            <input 
              style={{height:38,borderRadius:10,marginLeft:5}} 
              min={0}
              max={10}
              type="number" 
              value={this.state['rl'+(index+1)]}
              // defaultValue={this.state['rl'+(index+1)]}
              onChange={(e)=>{
                let key = 'rl'+(index+1)
                this.setState({[key]:e.target.value})
                //console.log('Value changed ',e.target.getAttribute("value"))
              }}
            />
            </label>
            </label>
          ))}
          </Grid> 
          :<div/>}  

    {this.state.current_type == '0'?
        <Grid container spacing={0} style={{}}>
          {Array.from(this.state.touch_valus).map((item, index) => (
            <label style={{display:'flex',flexDirection:'row',color:'white',justifyContent:'center',alignItems:'center', margin:10}}>
            {'TH '+(index+1)}
            <input 
              style={{height:38,borderRadius:10,marginLeft:5}} 
              min={0}
              max={255}
              type="number" 
              value={this.state['th'+(index+1)]}
              onChange={(e)=>{
                let key = 'th'+(index+1)
                this.setState({[key]:e.target.value})
              }}
            />
            </label>
          ))}
          </Grid> 
          :<div/>}   

          {parseInt(this.state.fan) > 0?
          <div style={{display:'flex',flexDirection:'row'}}>
          <label style={{display:'flex',flexDirection:'row',color:'white',justifyContent:'center',alignItems:'center', margin:10}}>
          {'Map 1 '}
          <input 
            style={{height:38,borderRadius:10,marginLeft:5}} 
            min={0}
            max={255}
            type="number" 
            value={this.state['map1']}
            onChange={(e)=>{
              let key = 'map1'
              this.setState({[key]:e.target.value})
            }}
          />
          </label>
          <label style={{display:'flex',flexDirection:'row',color:'white',justifyContent:'center',alignItems:'center', margin:10}}>
          {'Map 2 '}
          <input 
            style={{height:38,borderRadius:10,marginLeft:5}} 
            min={0}
            max={255}
            type="number" 
            value={this.state['map2']}
            onChange={(e)=>{
              let key = 'map2'
              this.setState({[key]:e.target.value})
            }}
          />
          </label>
          <Button style={{margin:'1%', backgroundColor:'gray', borderRadius:10, borderWidth:2,borderColor:'white', color:'white'}}
          onClick={() => {
            let data = {
              "state": {
                "desired": {
                  "command": "set_map",
                  "map1":this.state.map1,
                  "map2":this.state.map2
                }
              }
            }
            this.PUBLISH(this.state.current_thingName,data)
          }}
        > Update Map 
        </Button>
          </div>
          :<div/>
          }

        <Button style={{marginLeft:'30%',marginRight:'30%',marginTop:'2%' , backgroundColor:'gray', borderRadius:10, borderWidth:2,borderColor:'white', color:'white'}}
          onClick={() => {
            this.state.deviceFile = {
              "state": {
                "desired": {
                  "command": "saveFile",
                  "fileName": "model.json",
                  "data":{
                      "fan": this.state.fan,
                      "light": this.state.light,
                      "curtain": this.state.curtain,
                      "socket": this.state.socket,
                      "h_relay": this.state.h_relay,
                      "gate": this.state.gate,
                      "dimmer": this.state.dimmer,
                      "h_30_relay": this.state.h_relay,
                      "bell": this.state.bell,
                      "rgb": this.state.rgb,
                      "type": this.state.type,
                      "wf": this.state.wf,
                      "swct": this.state.swct,
                      "sw1": this.state.sw1,
                      "sw2": this.state.sw2,
                      "sw3": this.state.sw3,
                      "sw4": this.state.sw4,
                      "rl1": this.state.rl1,
                      "rl2": this.state.rl2,
                      "rl3": this.state.rl3,
                      "rl4": this.state.rl4
                    }
                }
              }
            }
            this.PUBLISH(this.state.current_thingName,this.state.deviceFile)
            console.log(JSON.stringify(this.state.deviceFile,this.state.deviceFile))

            setTimeout(() => {
              for(let i=0;i<10;i++){
                let C = (i+1)
                let key = 'th'+(i+1)
                let V = this.state[key]
                let data = {
                  "state": {
                    "desired": {
                      "command": "touchTime",
                      "c":C,
                      "v":V
                    }
                  }
                }
                this.PUBLISH(this.state.current_thingName,data)
              }
            }, 1000);
          }}
        > Update Device 
        </Button>
      </div>
    );
  }

  async delete_device(id){
    console.log('Deleting '+id);
    let url = 'https://iot.aizoteq.com/delete';
    if(Globals.LOCAL_TEST){
      url = Globals.LOCAL_HOST+'/delete';
    }else{
      url = 'https://iot.aizoteq.com/delete';
    }
  axios.get(url, {
    params: {
      action:'delete',
      id:id,
      api_key: 'xj1Pa0BoO04rgXccgUTug9BLmi7WUeOf8MrebIAI',
    }
  })
    .then((response) => {
      let data = response.data.body
      console.log('Delete Rep====> ', JSON.stringify(data));
      this.load_devices();
    })
  }

  render_menu = ()=>{
    const buttons = [
      <Button key="edit" onClick={() => {
        window.open('/dashboard?thingName='+this.state.current_thingName,'_self');
      }}> Edit</Button>,

      <Button key="monitor" onClick={() => {
        window.open('/dashboard?thingName='+this.state.current_thingName,'_self');
      }}>Monitor</Button>,

      <Button key="delete" onClick={() => {
        this.delete_device(this.state.current_id);
      }}>Delete</Button>,
    ];
    return (
      <div
        className="menu"
        style={{
          top: this.state.yPos,
          left: this.state.xPos,
        }}
      >

      <ButtonGroup
        className = "menu-button"
        orientation="vertical"
        aria-label="vertical contained button group"
        variant="contained"
      >
        {buttons}
      </ButtonGroup>

{/* <Box
      sx={{
        width: 300,
        height: 300,
        backgroundColor: 'primary.dark',
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    /> */}


        {/* <div className='menu_item' onClick={() => {
          console.log('Goto Monitor')
          window.open('/dashboard?thingName='+this.state.current_thingName,'_self');
          }}> Monitor </div>
        <div className='menu_item' onClick={() => {
          console.log('Goto Monitor')
          window.open('/dashboard?thingName='+this.state.current_thingName,'_self');
          }}> Delete </div> */}
      </div>
    );
  }


  render_device = (person, idx) => {
    let data = []
    let room = ''
    for (const [key, value] of Object.entries(person)) {
      //console.log('room==========>',key)
      room = key
      console.log('data==========>',JSON.stringify(value))
      data = value
    }
    return (
      <div key={room + idx.toString()} style={{display:'flex',
        flexDirection:'column',marginLeft:-25}}>
        <div className="room_name">{room}</div>
        {this.state.showMenu?this.render_menu():<div/>}
        {/* <Grid container spacing={{ xs:2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> */}
        <Grid container spacing={0} style={{}}>
        {/* <Grid container > */}

          {Array.from(data).map((item, index) => (
            <Grid key={room + index.toString()} item xs={'auto'} >
              <Card variant="outlined" className="card" style={this.state.files[item.thingName + '_S' + (index + 1)] == 1 ? Styles.card_white : Styles.card_black}

                  onContextMenu={(e) => {
                    e.preventDefault(); // prevent the default behaviour when right clicked
                    //console.log("Right Click on ",item.thingName);
                    // if(item.type == 'switch'){
                    if(true){
                    this.setState({
                        xPos: `${e.pageX}px`,
                        yPos: `${e.pageY}px`,
                        showMenu: true,
                        current_thingName:item.thingName,
                        current_id:item.id
                      });
                    }
                  }}

                onClick={() => {
                  if(item.type == 'fan'){
                    this.state.current_thingName = item.thingName
                    this.state.current_index = index
                    this.state.current_value = this.state.files[item.thingName + '_V' + (index + 1)]
                    this.state.current_state =  this.state.files[item.thingName + '_S' + (index + 1)]
                    if(this.state.current_state == 0){{this.state.active = 'OFF'}}
                    else if(this.state.current_value > 75){this.state.active = 'TURBO'}
                    else if(this.state.current_value <= 75 && this.state.current_value > 50){this.state.active = 'HIGH'}
                    else if(this.state.current_value <= 50 && this.state.current_value > 25){this.state.active = 'MED'}
                    else if(this.state.current_value <= 25 && this.state.current_value > 0){this.state.active = 'LOW'}
                    else{this.state.active = 'OFF'}
                    this.setState({fanRegulator:true})
                  }else{
                    let S = 1
                    if(this.state.files[item.thingName + '_S' + (index + 1)] == 1){
                      S = 0
                    }else{  
                      S = 1
                    }
                  this.HANDLE_CLICK(item.thingName, index, S, '254')
                  }
                }}
              >
                <div className="item" >
                  <div className="icon_container">
                    <div className="device_icon">
                        <img className={this.state.files[item.thingName + '_S' + (index + 1)] == 0?'image_black':this.state.files[item.thingName + '_S' + (index + 1)] == 1?'image_white':'image_offline'} src={item.type == 'fan' ? this.images[item.icon + '_rotate'] : this.images[item.icon]}onClick={() => console.log('Hi')}  alt='scsa'></img>
                    </div>
                    {item.type == 'switch'?<div className="energymeter_icon">
                        <img className={this.state.files[item.thingName + '_S' + (index + 1)] == 0?'image_black':this.state.files[item.thingName + '_S' + (index + 1)] == 1?'image_white':'image_offline'} src={this.images['Energymeter']} alt='scsa'></img>
                    </div>
                    :<div/>}
                  </div>
                  <div style={this.state.files[item.thingName + '_S' + (index + 1)] == 0 ? Styles.white :this.state.files[item.thingName + '_S' + (index + 1)] == 1? Styles.black:Styles.offline}>{item.name}</div>
                  {item.type == 'fan' && this.state.files[item.thingName + '_S' + (index + 1)] == 1?
                    <div style={this.state.files[item.thingName + '_S' + (index + 1)] == 0 ? Styles.white : Styles.black}>
                      {
                      this.state.files[item.thingName + '_V' + (index + 1)] > 75 ? 'TURBO' 
                      : this.state.files[item.thingName + '_V' + (index + 1)] <= 75 && this.state.files[item.thingName + '_V' + (index + 1)] > 50 ? 'HIGH'
                      : this.state.files[item.thingName + '_V' + (index + 1)] <= 50  && this.state.files[item.thingName + '_V' + (index + 1)] >25 ? 'MED'
                      : this.state.files[item.thingName + '_V' + (index + 1)] <= 25 && this.state.files[item.thingName + '_V' + (index + 1)] >0? 'LOW'
                      : 'off'}
                    </div>
                  :<div style={this.state.files[item.thingName + '_S' + (index + 1)] == 0 ? Styles.white :this.state.files[item.thingName + '_S' + (index + 1)] == 1 ?  Styles.black:Styles.offline}>{this.state.files[item.thingName + '_S' + (index + 1)] == 1 ? 'on' : 'off'}</div>
                }
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

    );
  }

  // componentWillMount() {
  //   mql.addListener(this.mediaQueryChanged);
  // }

  // componentWillUnmount() {
  //   this.state.mql.removeListener(this.mediaQueryChanged);
  // }
  // onSetSidebarOpen(open) {
  //   this.setState({ sidebarOpen: open });
  // }

  // mediaQueryChanged() {
  //   this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  // }







  // handleContextMenu = (e) => {
  //   e.preventDefault();
  
  //   this.setState({
  //     xPos: `${e.pageX}px`,
  //     yPos: `${e.pageY}px`,
  //     showMenu: true,
  //   });
  // };



  handleClick = async (event) => {
    //setAnchorEl(event.currentTarget);
    console.log('Clicked ', event)
    if (event == 'Logout') {
      await Auth.signOut()
      window.open('/', '_self');
    }
  }


  handleChange = (event)=>{
    console.log('handleChange event:',event)
    //this.state.active = event
    let S = 1
    let V = 254
    if(event == 'OFF'){
      V = this.state.current_value
      S = 0
    }else if(event == 'LOW'){
      V = 25
      S = 1
    }
    else if(event == 'MED'){
      V = 50
      S = 1
    }
    else if(event == 'HIGH'){
      V = 75
      S = 1
    }
    else if(event == 'TURBO'){
      V = 100
      S = 1
    }
    this.HANDLE_CLICK(this.state.current_thingName,this.state.current_index,S,V)
    //this.setSpeed(event)
  }

  setSpeed = (speed)=>{
    console.log('setSpeed ',speed)
    this.setState({active:speed})
  }

  handleOusideClick = (event)=>{
    event.preventDefault();
    const value = event.target.getAttribute("value")
    if(value == 'outside'){
      this.setState({fanRegulator:false})
    }
  }

  onInputChange = (value,i)=>{
    console.log('value:'+value+' i:'+i)
    this.setState({segment_index:i})
  }

  speedSelected = (speed)=>{
    console.log('speed slected ',speed)
  }


   ToggleGroup = ()=> {
    const types = ["TURBO", "HIGH", "MED","LOW","OFF"];
    var [active, setActive] = useState(types[0]);
    active = this.state.active
    const ButtonToggle = styled.button(
    (props => props.active && props.name == 'TURBO'?
    {
      opacity: 1,
      color: 'black',
      fontWeight:'bold',
      background: 'white',
      width: 150,
      height:50,
      borderColor:'white',
      borderWidth:0,
      borderBottomWidth:1,
      borderBottomColor:'black',
      boxShadow: "0px 3px 10px white",
      borderTopLeftRadius:30,
      borderTopRightRadius:30
    }:!props.active && props.name == 'TURBO'?
    {
      opacity: 1,
      color: 'white',
      fontWeight:'bold',
      background: 'gray',
      width: 150,
      height:50,
      borderColor:'white',
      borderWidth:0,
      borderBottomWidth:1,
      borderBottomColor:'black',
      boxShadow: "0px 0px 0px white",
      borderTopLeftRadius:30,
      borderTopRightRadius:30
    }:props.active && props.name == 'OFF'?
    {
      opacity: 1,
      color: 'black',
      fontWeight:'bold',
      background: 'white',
      width: 150,
      height:50,
      borderColor:'white',
      borderWidth:0,
      boxShadow: "0px 3px 10px white",
      borderBottomLeftRadius:30,
      borderBottomRightRadius:30
    }:!props.active && props.name == 'OFF'?
    {
      opacity: 1,
      color: 'white',
      fontWeight:'bold',
      background: 'gray',
      width: 150,
      height:50,
      borderColor:'white',
      borderWidth:0,
      boxShadow: "0px 0px 0px white",
      borderBottomLeftRadius:30,
      borderBottomRightRadius:30
    }
    :props.active?
    {
      opacity: 1,
      color: 'black',
      fontWeight:'bold',
      background: 'white',
      width: 150,
      height:50,
      borderColor:'white',
      borderWidth:0,
      boxShadow: "0px 3px 10px white",
      borderBottomLeftRadius:0,
      borderBottomRightRadius:0
      }:
      {
        opacity: 1,
        color: 'white',
        fontWeight:'bold',
        background: 'gray',
        width: 150,
        height:50,
        borderColor:'white',
        borderWidth:0,
        boxShadow: "0px 0px 0px white",
        borderBottomWidth:1,
        borderBottomColor:'black',
        borderBottomLeftRadius:0,
        borderBottomRightRadius:0
      }
    ));



    return (
      <div 
      value='outside'
      onClick={this.handleOusideClick}
      className="regulator"
      // style={{
      //   display:'flex',
      //   flexDirection:'column',
      //   backgroundColor:'rgba(40,50,50,0.9)',
      //   borderRadius:10,
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   height:window.innerHeight,
      //   position:'absolute',
      //   top:0,
      //   bottom:0,
      //   right:0,
      //   left:0
      // }}
      >
        <div 
        value='outside'
        onClick={this.handleOusideClick}
        style={{
        display:'flex',
        flexDirection:'column',
        // backgroundColor:'rgba(40,50,50,0.5)',
        borderRadius:30,
        justifyContent: 'center',
        alignItems: 'center',
        padding:0,
      }}>
        {types.map((type) => (
          <ButtonToggle active={active === type} name={type} onClick={() => {
            this.handleChange(type)
            setActive(type)
            }}>
            {type}
          </ButtonToggle>
        ))}
      </div>
      </div>
      
    );
  }




  render() {

  //   const segments = [
  //   {
  //     value:'OFF',
  //     label:'OFF',
  //   },
  //   {
  //     value:'LOW',
  //     label:'LOW',
  //   },
  //   {
  //     value:'MED',
  //     label:'MED',
  //   },
  //   {
  //     value:'HIGH',
  //     label:'HIGH',
  //   },
  //   {
  //     value:'TURBO',
  //     label:'TURBO',
  //   }
  // ]



    return (
      
      <div style={{
        display:'flex',
        flexDirection:'column',
        flex:1,
        // height:window.innerHeight,
      }}
      >
          
      <Box style={{}}>
      <AppBar position="static" style={{
        color:'white', 
        backgroundColor:'#424242' }}>
        <Toolbar
        style={{}}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <div
          style={{diaplay:'flex',flex:1}}
          ></div>

          {this.email === 'sayyidyaseen@gmail.com'?
          <img className={'image_black'} 
            onClick={()=>{
              if(this.state.config_window == true){
                this.setState({config_window:false})
                sessionStorage.setItem("config_window", false);
              }else{
                this.setState({config_window:true})
                sessionStorage.setItem("config_window", true);
              }
            }}
            style={{padding:5, marginRight:20}} 
            src={require('../assets/settings.png')} alt='scsa'>
          </img>
          :<div/>}

          <Button 
          onClick={()=>{
            this.handleClick('Logout')
          }}
          style={{
            flexDirection:'row',
            justifyContent:'flex-end'
            }} color="inherit">Logout</Button>
        </Toolbar>
      </AppBar>
      </Box>

      
      {/* <Sidebar
        sidebar={<b>Sidebar content</b>}
        open={true}
        onSetOpen={this.onSetSidebarOpen}
        styles={{ sidebar: { background: "white" } }}
      > */}
            <ul style={{}}>
              {
              this.state.arrayHolder.map((data,index)=>{
                return this.render_device(data,index)
              })
              }
            </ul>
            {this.state.config_window == true?
            <ul style={{}}>
            <this.render_device_pins/>
            </ul>
            :<div/>}
          
          {this.state.fanRegulator?
          <this.ToggleGroup />
        :<div/>}
      {/* </Sidebar> */}


      </div>
      



    )
  }
}

export default Signin








const Styles = {
  card_white: {
    backgroundColor: '#fff',
    borderRadius: 15
  },
  card_black: {
    backgroundColor: '#424242',
    borderRadius: 15
  },
  white: {
    color: '#fff',
    fontSize:15
  },
  black: {
    color: '#000',
    fontSize:15
  },
  offline:{
    color: '#757575',
    fontSize:15
  },
  menu_item: {
    backgroundColor: '#757575',
    borderRadius: 10,
    margin: 10,
    color: 'white',
    fontWeight: 'bold'
  },
  segment:{
    backgroundColor:'red',
    padding:10
  },
  segment_container:{
  }

};



