import React, { Component,Fragment } from 'react'
import { authenticateUser } from "./Cognito"
import './styles.css';
// import fetchTimeout from 'fetch-timeout';
import axios from "axios";
import Cookies from 'js-cookie';
//import cookie from 'react-cookie';
import fetch from 'node-fetch';
import Signup from './Signup'
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import logo from './logo.png'












class Signin extends Component {
  constructor (props) {
    super(props)
    this.changeEmail = this.changeEmail.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.handleSigninSubmit = this.handleSigninSubmit.bind(this)

    this.GetAuthCode = this.GetAuthCode.bind(this)
    this.GetToken = this.GetToken.bind(this)

    this.state = {
      email: '',
      password: '',
      loading: false,
      redirectUri:'',
      url_state:''
    }
  }
  

  changeEmail (e) {
    this.setState({ email: e.target.value })
  }

  changePassword (e) {
    this.setState({ password: e.target.value })
  }

  handleSigninSubmit (e) {
    e.preventDefault()
    this.setState({ loading: true })
    console.log('Entered:', this.state)
    // authenticateUser(this.state.email, this.state.password, (err, result) => {
    //   if (err) {
    //     console.log(err)
    //     this.setState({ loading: false })
    //     return
    //   }
    //   console.log(result)
    //   this.setState({ loading: false })

    //   const queryParams = new URLSearchParams(window.location.search);
    //   const state = queryParams.get('state');
    //   const redirect_uri = queryParams.get('redirect_uri');
    //   const scope = queryParams.get('scope');
    //   const response_type = queryParams.get('response_type');
    //   const client_id = queryParams.get('client_id');
    //   const access_type = queryParams.get('access_type');
      
    //   console.log('------------queryParams---------------',window.location.search)
    //   console.log('state: ',state);
    //   console.log('redirect_uri: ',redirect_uri);
    //   console.log('scope: ',scope);
    //   console.log('response_type: ',response_type);
    //   console.log('access_type: ',access_type);
    //   console.log('client_id: ',client_id);

    //   // var url = redirect_uri+'?code='+'sayyidyaseen@gmail.com'+'&state='+state
    //   this.url_state = state
    //   this.state.redirectUri = redirect_uri
    //   this.GetAuthCode(redirect_uri,state)
    //   //var win = window.open(url,'_self');
    //   // win.focus();
    //   //window.location.reload()
    // })


    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get('state');
    const redirect_uri = queryParams.get('redirect_uri');
    const scope = queryParams.get('scope');
    const response_type = queryParams.get('response_type');
    const client_id = queryParams.get('client_id');
    const access_type = queryParams.get('access_type');
    
    console.log('------------queryParams---------------',window.location.search)
    console.log('state: ',state);
    console.log('redirect_uri: ',redirect_uri);
    console.log('scope: ',scope);
    console.log('response_type: ',response_type);
    console.log('access_type: ',access_type);
    console.log('client_id: ',client_id);

    // var url = redirect_uri+'?code='+'sayyidyaseen@gmail.com'+'&state='+state
    this.url_state = state
    this.state.redirectUri = redirect_uri
    this.GetAuthCode(redirect_uri,state)

  }

  componentDidMount(){
    console.log('props:',this.props.agent)
    document.title = "AiZo"
  }


  async GetToken(code,redirect_uri){
    axios.post('/token', {
      params: {
        code:code,
        redirect_uri:redirect_uri
      }
    })
    .then(function (response) {
      var code = response.data.body.code
      console.log('Recived data: ',code);
      //var url = redirect_uri+'?code='+code+'&state='+url_state
      //window.open(url,'_self');
    })
  }



  async GetAuthCode(redirect_uri,url_state){
    let RQ_URL = ''
    let AuthCode = ''

    var CLIENT_ID="6lpmrn2815kcqvgrutap0ush6f";
    var CLIENT_SECRET="1n4fvpgesf6ctgukt0mpo5kvovehocteonv0tngnd6i3anphpp0n";
    // var CLIENT_ID="4hr5ambggej4fukgqf7ra6ievi";
    // var CLIENT_SECRET="m5e318odf58l222nv4l3t5390b6md0g9n10ha5lrlo9fcreoqvm";
    var RESPONSE_TYPE="code";
    var REDIRECT_URI= encodeURIComponent("https://aizoteq.com/aizoapp");
    var SCOPE="openid";
    var AUTH_DOMAIN= "aizo.auth.ap-south-1.amazoncognito.com";
    // var USERNAME="sayyidyaseen@gmail.com";
    // var PASSWORD="yaseen1234";
    var CODE_CHALLENGE_METHOD="S256";

    var USERNAME='sayyidyaseen@gmail.com'
    var PASSWORD='yaseen1234';
    //4hr5ambggej4fukgqf7ra6ievi

    //http://localhost:3000/?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fhomegraph&redirect_uri=https%3A%2F%2Fdevelopers.google.com%2Foauthplayground&response_type=code&client_id=6lpmrn2815kcqvgrutap0ush6f&access_type=offline
    
    var csrfRequestUrl = `https://${AUTH_DOMAIN}/oauth2/authorize?response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}`
    //var csrfRequestUrl = `https://aizo.auth.ap-south-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=6lpmrn2815kcqvgrutap0ush6f&redirect_uri=https%3A%2F%2Faizoteq.com%2Faizoapp&scope=openid&code_challenge_method=S256&code_challenge=PxiTu377dz/OBpPmbgoMX1bsQ2TsfKyVOP5cbfkSbO8=`
    // Post CSRF Token and username/password to /login endpoint
    var codeRequestUrl = `https://${AUTH_DOMAIN}/login?response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&redirect_uri=${redirect_uri}`;
    
    console.log('codeRequestUrl-----------',codeRequestUrl)
    let self = this
    // var apiUrl = 'https://xxv54b8qhg.execute-api.ap-south-1.amazonaws.com/v1/authcode?username=sayyidyaseen@gmail.com&password=yaseen1234'
    var apiUrl = 'https://xxv54b8qhg.execute-api.ap-south-1.amazonaws.com/v1/authcode'

    //https://aizo.auth.ap-south-1.amazoncognito.com/login?response_type=code&client_id=6lpmrn2815kcqvgrutap0ush6f&redirect_uri=https://developers.google.com/oauthplayground


    //window.open(csrfRequestUrl,'_self');

    // axios.get('https://auth.aizo.in/test', {
    //   params: {
    //     username: this.state.email,
    //     password:this.state.password,
    //     redirect_uri:redirect_uri
    //   }
    // })
    // .then(function (response) {
    //   console.log('Recived response: ',response);

    // })

    // axios.post('/auth', {
    axios.post('/auth', {
      // headers: {
      //   'x-api-key ': `xj1Pa0BoO04rgXccgUTug9BLmi7WUeOf8MrebIAI` 
      // },
      params: {
        username: this.state.email,
        password:this.state.password,
        redirect_uri:redirect_uri
      }
    })
    .then(function (response) {
      var code = response.data.body.code
      console.log('Recived code: ',code);
      var url = redirect_uri+'?code='+code+'&state='+url_state
      window.open(url,'_self');
    })

    return
    }

  

  render () {
    console.log('Path:',window.location.href)
    return (
      // <Header />
      
      <div  style={{
        backgroundColor:'#000',
        textAlign:'center',
        position:'absolute',
        flex:0,
        right:0,
        left:0,
        top:0,
        bottom:0
        
        }}  className="Signin" >

        <form style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }} onSubmit={this.handleSigninSubmit}>
        <img style={{
              width:200,              
            }} src={logo} className="App-logo" alt="logo" />
        <h4 style={{
            color: '#fff' 
             }}>By signing in, you are authorizing Google to control your devices</h4>
          <div>
            <input
              id="input1"
              value={this.state.email}
              placeholder='Email'
              type='text'
              onChange={this.changeEmail} />
          </div>
          <div>
            <input
              id="input2"
              value={this.state.password}
              placeholder='Password'
              type='password'
              minLength={6}
              onChange={this.changePassword} />
          </div>
          <div>
            <button 
            style={{backgroundColor:'#fff'}}
            id = "button"
            type='submit' disabled={this.state.loading}>LogIn
            </button>
          </div>
        </form>
      </div>
      
    )
  }
}

export default Signin



