import React, { Component } from 'react'
import { Amplify, Auth, PubSub, Hub } from 'aws-amplify';
import { AWSIoTProvider, CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import axios from "axios";
import Button from "@material-ui/core/Button";
import { SegmentedControl } from 'segmented-control'
import { ReactSession } from 'react-client-session';
import Globals from './Globals';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import {
    Grid,
    Card,
    // CardContent,
    // Typography,
    // CardHeader
  } from '@material-ui/core/'

  import './Dashboard.css'
  import GaugeChart from 'react-gauge-chart'

  import {
    BarChart,
    Bar,
    Brush,
    Cell,
    CartesianGrid,
    ReferenceLine,
    ReferenceArea,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ErrorBar,
    LabelList,
    Rectangle,
    LineChart,
    Line,
  } from 'recharts';

  ReactSession.setStoreType("localStorage");
// ReactSession.set("username", "Bob");
// ReactSession.get("username");  // Returns "Bob"


class Dashboard extends Component {
    constructor(props) {
      super(props)

      this.SUBSCRIBE = this.SUBSCRIBE.bind(this)
      this.PUBLISH = this.PUBLISH.bind(this)
      this.HANDLE_MESSAGE = this.HANDLE_MESSAGE.bind(this)
      this.FETCH_DB_DATA = this.FETCH_DB_DATA.bind(this)

      this.state = {
        db_data:[],
        chart:[{name:'power',value:'0'},{name:'graph',value:'0'}],
        device_status:'OFF',
        current_thingName:'',
        energy:{},
        mqttConnected:false,
        modules_inputs:[{name:'Status',value:'OFF'},{name:'Voltage',value:'0.0V'},{name:'Current',value:'0.0A'},{name:'Kwh',value:'0.0Kwh'}],
        kwh_status:[{name:'Today',value:'0.0Kwh'},{name:'This Week',value:'0.0Kwh'},{name:'This Month',value:'0.0Kwh'},{name:'This Year',value:'0.0Kwh'}]
      }
    }


    FETCH_DB_DATA = async(thingName)=>{
      let url = 'https://iot.aizoteq.com/get_energy';
      if(Globals.LOCAL_TEST){
         url = 'http://localhost:3001/get_energy';
      }else{
         url = 'https://iot.aizoteq.com/get_energy';
      }
      const filter = 'month';
      const month_names = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      axios.get(url, {
                params: {
                  thingName: thingName,
                  filter:filter
                }
              }).then((response) => {
                  //let data = response.data.body
                  //console.log('axios data====> ', JSON.stringify(data));
                  let data = response.data;
                  this.state.db_data = []
                  let energy_prev = "0.0";
                  data.forEach(element => {
                    let D = JSON.parse(JSON.stringify(element));
                    //D['name'] = element.hour;
                    D['month'] = month_names[element.month-1];
                    D['energy'] = (parseFloat(element.energy) - parseFloat(energy_prev)).toFixed(2);
                    this.state.modules_inputs[3]['value'] = (parseFloat(element.energy) - parseFloat(energy_prev)).toFixed(2);
                    energy_prev = parseFloat(element.energy);
                    this.state.db_data.push(D);
                    //console.log('D====>',D);
                  });
                  //console.log('axios this.state.db_data====> ', JSON.stringify(this.state.db_data));
                  this.setState({db_data:this.state.db_data})
                  
                  //this.setState({db_data:response.data})
                  //console.log('axios response====> ', JSON.stringify(response.data));
                })
    }

    SUBSCRIBE = async (thingName) => {
      //if(!this.state.mqttConnected){return}
      try {
        console.log('subscribing==========>', thingName)
        let topic = '$aws/things/' + thingName + '/shadow/update'
        await Amplify.PubSub.subscribe(topic).subscribe({
          next: data => {
            //console.log('Message received', JSON.stringify(data.value))
            this.HANDLE_MESSAGE(thingName, data.value)
          },
          error: error => console.error(error),
          close: () => console.log('Done'),
        });

        let message = { state: { desired: { command: "get_energy" } } }
        this.PUBLISH(thingName, message)
        
        setInterval(() => {
          let message = { state: { desired: { command: "get_energy" } } }
          this.PUBLISH(thingName, message)
          //console.log('Engery request..! topic',thingName)
        }, 60000);
        
      } catch (error) {
        console.log('Error subscribing==========>', error)
      }
  
    }
  
    PUBLISH = async (thingName, messege) => {
      //if(!this.state.mqttConnected){return}
      let topic = '$aws/things/' + thingName
      await PubSub.publish(topic, messege);
    }

    HANDLE_MESSAGE = (thingName, message) => {
        let command = message.state.desired.command
        let C, S, V, key
        console.log('Message:',command);
        if (command == 'energy') {
          thingName = message.state.desired.id;
          let key = thingName+'_voltage';
          this.state.energy[key] = message.state.desired.v;
          this.state.modules_inputs[1]['value'] = message.state.desired.v+'V'

          key = thingName+'_current';
          this.state.energy[key] = message.state.desired.c
          this.state.modules_inputs[2]['value'] = message.state.desired.c+'A'

          key = thingName+'_power';
          this.state.energy[key] = message.state.p;
          this.state.chart[0]['value'] = message.state.desired.p;
          //this.state.modules_inputs[0]['value'] = message.state.desired.p+'W'


          key = thingName+'_energy';
          this.state.energy[key] = message.state.desired.e
          //this.state.modules_inputs[3]['value'] = message.state.desired.e+'Kwh'

          if(message.state.desired.s == '1'){
            this.state.device_status = 'ON'
            this.state.modules_inputs[0]['value'] = 'ON'
          }else{
            this.state.device_status = 'OFF'
            this.state.modules_inputs[0]['value'] = 'OFF'
          }
          this.setState({energy:this.state.energy})
        }
        else if(command == 'power') {
          if(message.state.desired.s == '1'){
            this.state.device_status = 'ON'
            this.state.modules_inputs[0]['value'] = 'ON'
          }else{
            this.state.device_status = 'OFF'
            this.state.modules_inputs[0]['value'] = 'OFF'
          }
          this.setState({device_status:this.state.device_status})
        }
      }

      handleClick = async (event) => {
        if (event == 'Logout') {
          await Auth.signOut()
          window.open('/', '_self');
        }
      }

      async componentDidMount() {
        if(ReactSession.get("username")){
          console.log('ReactSession.get("username"):',ReactSession.get("username"))
          const searchParams = new URLSearchParams(document.location.search)
          this.state.current_thingName = searchParams.get('thingName');
          this.SUBSCRIBE(this.state.current_thingName );
          this.FETCH_DB_DATA(this.state.current_thingName);
        }else{
          window.open('/','_self');
          console.log('No seeeion found');
        }


      // Hub.listen('pubsub', (data) => {
      //   const { payload } = data;
      //   if (payload.event === CONNECTION_STATE_CHANGE) {
      //     const connectionState = payload.data.connectionState;
      //     console.log('connectionState dashboard====>', connectionState);
      //     if(connectionState === 'Connected'){
      //       this.setState({mqttConnected:true})
      //     }else if(connectionState === 'Disconnected'){
      //         Auth.currentCredentials().then((info) => {
      //           const cognitoIdentityId = info.identityId;
      //           console.log('cognitoIdentityId==========>',cognitoIdentityId)
      //           axios.get('https://192.168.1.197:3001/attachpolicy', {
      //           //axios.get('https://iot.aizoteq.com/attachpolicy', {
      //             params: {
      //               cognitoIdentityId: cognitoIdentityId,
      //               api_key: 'xj1Pa0BoO04rgXccgUTug9BLmi7WUeOf8MrebIAI',
      //             }
      //           })
      //             .then((response) => {
      //               //let data = response.data.body
      //               //console.log('axios data====> ', JSON.stringify(data));
      //               //console.log('axios response====> ', JSON.stringify(response));
      //             })
                
      //         });
      //         //https://x7gwnad4hj.execute-api.ap-south-1.amazonaws.com/v1/attach?cognitoIdentityId=ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d
      //         //https://x7gwnad4hj.execute-api.ap-south-1.amazonaws.com/v1/attachpolicy?cognitoIdentityId=ap-south-1:518fb5b2-330a-4b74-83f1-f9461b97910d
          
      //       }
      //     else{
      //       this.setState({mqttConnected:true})
      //     }
      //   }
      // });

    }


    render(){
      const hour_data = [
        {
          name: 'Jan',
          uv: 400,
        },
        {
          name: 'Feb',
          uv: 300,
        },
        {
          name: 'March',
          uv: 200,
        },
        {
          name: 'April',
          uv: 278,
        },
        {
          name: 'May',
          uv: 189,
        },
        {
          name: 'June',
          uv: 239,
        },
        {
          name: 'July',
          uv: 349,
        },
        {
          name: 'August',
          uv: 349,
        },
        {
          name: 'Sep',
          uv: 349,
          amt: 2100,
        },        
        {
          name: 'Oct',
          uv: 349,
          amt: 2100,
        },
        {
          name: 'Nov',
          uv: 349,
        },
        {
          name: 'Dec',
          uv: 349,
        },
      ];


      const button_data = [
          {
            name:'Status',
            value:this.state.device_status,
            BGcolor:this.state.device_status=='ON'?'white':'gray',
            color:this.state.device_status=='ON'?'black':'white',
            command:'power'
          },
          {
            name:'Reset',
            value:'Reset',
            BGcolor:'red',
            color:'white',
            command:'reset_energy'
          }
        ]

        const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
          return <text x={x + width / 2.0} y={y} fill="white" textAnchor="middle" dy={-6}>{`${value}Kwh`}</text>;
        };

        return(
            <div>
              <Box style={{}}>
                <AppBar position="static" style={{
                  color:'white', 
                  backgroundColor:'#424242' }}>
                  <Toolbar
                  style={{}}
                  >
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <div
                    style={{diaplay:'flex',flex:1}}
                    ></div>

                    {this.email === 'sayyidyaseen@gmail.com'?
                    <img className={'image_black'} 
                      onClick={()=>{
                        if(this.state.config_window == true){
                          this.setState({config_window:false})
                          sessionStorage.setItem("config_window", false);
                        }else{
                          this.setState({config_window:true})
                          sessionStorage.setItem("config_window", true);
                        }
                      }}
                      style={{padding:5, marginRight:20}} 
                      src={require('../assets/settings.png')} alt='scsa'>
                    </img>
                    :<div/>}

                    <Button 
                    onClick={()=>{
                      this.handleClick('Logout')
                    }}
                    style={{
                      flexDirection:'row',
                      justifyContent:'flex-end'
                      }} color="inherit">Logout</Button>
                  </Toolbar>
                </AppBar>
                </Box>
                <Grid container spacing={0} 
                     direction="row"
                     alignItems="center"
                     justifyContent="center"
                     key='dash'
                    style={{}}>
                    {Array.from(this.state.modules_inputs).map((item, index) => (
                        <Card variant="outlined" className="dashboard_card" style={Styles.card_black}
                            onClick={() => {
                                console.log('Sending pintest message')
                                //this.PIN_TEST(this.state.current_thingName,item.pin)
                            }}
                            >
                              <div className="dashboard_item" >
                                  <div style={Styles.white}>{item.name}</div>
                              </div>
                              <hr
                                  style={{
                                      color: 'white',
                                      backgroundColor: 'white',
                                      height: 1,
                                      marginRight:20,
                                      marginLeft:20
                                  }}
                              />
                              <div className="value_container" >
                                  {index == 0?
                                  <Button style={{marginLeft:10, backgroundColor:this.state.device_status == 'ON'?'white':'gray', borderRadius:10, borderWidth:2,borderColor:'white', color:this.state.device_status == 'ON'?'black':'white'}}
                                    onClick={() => {
                                      let s = '0'
                                      if(this.state.device_status == 'ON'){
                                        s = 'off'
                                      }else{
                                        s = 'on'
                                      }
                                      let file = {
                                        "state": {
                                          "desired": {
                                            "command": 'power',
                                            "c": "1",
                                            "s": s,
                                            "v": "254",
                                            "u": "Dashbaord"
                                          }
                                        }
                                      }
                                      this.PUBLISH(this.state.current_thingName,file)
                                    }}
                                  > {item.value} 
                                </Button>
                                  :
                                  <div style={Styles.value}>{item.value}</div>
                                  }
                              </div>
                        </Card>
                    ))}
                </Grid> 

                <Grid container spacing={0} 
                     direction="row"
                     alignItems="center"
                     justifyContent="center"
                     key='dash_graph'
                    style={{}}>
                    {Array.from(this.state.chart).map((item, index) => (
                          
                            <Card variant="outlined" className="graph_card" style={Styles.graph_item}
                            onClick={() => {
                                  console.log('power: '+index,item.value)
                              }}
                            >
                                {index === 0?
                                <GaugeChart id="gauge-chart5"
                                    nrOfLevels={420}
                                    arcsLength={[0.3, 0.5, 0.2]}
                                    colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                    //percent={parseInt(this.state.power)/1000 }
                                    percent={parseInt(item.value)/1000}
                                    arcPadding={0.02}
                                    formatTextValue = {value => value*10+'W'}
                                    />
                                  :
                                  <div className="graph_container">
                                  {/* <SegmentedControl
                                    name="oneDisabled"
                                    options={[
                                      { label: "Day", value: "day", default: false },
                                      { label: "Week", value: "week", default: false  },
                                      { label: "Month", value: "month", default: true },
                                      { label: "Year", value: "year" , default: false }
                                    ]}
                                    setValue={newValue =>{
                                       console.log('newValue',newValue)
                                       this.setState({filter:newValue});
                                      }}
                                    style={{ color: 'gray' }} // purple400
                                    /> */}
                                    <div className="lineChart_container">
                                      {/* <LineChart width={550} height={200} data={this.state.db_data}>
                                        <XAxis dataKey="hour"/>
                                        <YAxis/>
                                        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                                        <Line type="monotone" dataKey="power" stroke="#8884d8" />
                                      </LineChart> */}
                                        <BarChart width={550} height={280} data={this.state.db_data}>
                                          <XAxis dataKey="month" stroke="#ffffff"/>
                                          <YAxis stroke="#ffffff" />
                                          <Bar dataKey="energy" barSize={30} fill="#8884d8"
                                            label={renderCustomBarLabel}/>
                                      </BarChart>
                                    </div>
                                  </div>
                                }
                            </Card>
                    ))}
                </Grid> 

                <Grid container spacing={0} 
                     direction="row"
                     alignItems="center"
                     justifyContent="center"
                     key='dash3'
                    style={{}}>
                    {Array.from(this.state.kwh_status).map((item, index) => (
                        <Card variant="outlined" className="dashboard_card" style={Styles.card_black}
                            onClick={() => {
                                console.log('Sending pintest message')
                                //this.PIN_TEST(this.state.current_thingName,item.pin)
                            }}
                            >
                              <div className="dashboard_item" >
                                  <div style={Styles.white}>{item.name}</div>
                              </div>
                              <hr
                                  style={{
                                      color: 'white',
                                      backgroundColor: 'white',
                                      height: 1,
                                      marginRight:20,
                                      marginLeft:20
                                  }}
                              />
                              <div className="value_container" >
                                  <div style={Styles.value}>{item.value}</div>
                              </div>
                        </Card>
                    ))}
                </Grid> 

            </div>
        );
    }
}

export default Dashboard


const Styles = {
    card_white: {
      backgroundColor: '#fff',
      borderRadius: 15
    },
    card_black: {
      backgroundColor: '#424242',
      borderRadius: 15,
      alignItems:'flex-end'
    },
    graph_item:{
        backgroundColor: '#424242',
        borderRadius: 15,
        alignItems:'center',
    },
    white: {
      color: '#fff',
      fontSize:20,
      fontWeight:'bold'
    },
    value: {
        color: '#fff',
        fontSize:35,
        fontWeight:'bold'
      },
    black: {
      color: '#000',
      fontSize:15
    },
    offline:{
      color: '#757575',
      fontSize:15
    },
    menu_item: {
      backgroundColor: '#757575',
      borderRadius: 10,
      margin: 10,
      color: 'white',
      fontWeight: 'bold'
    },
    segment:{
      backgroundColor:'red',
      padding:10
    },
    segment_container:{
    }
  
  };