import React, { Component }  from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Menu from "./Components/Menu";
// import Brand from "./Components/Brand";
import Error from "./Components/Error";
import SignIn from "./Components/Signin"
import Devices from "./Components/Devices"
import Dashboard from "./Components/Dashboard"
import Signin_google from "./Components/Signin_google"

import './index.css';
 



// function App() {
//   return (
//     <div  className="App">
//       <Router >
//         {/* <Menu /> */}
//         <Switch>
//           <Route path="/error">
//             <Error />
//           </Route>
//           {/* <Route path="/:brand">
//             <Brand />
//           </Route> */}
//           <Route path="/oauth2/authorize/google">
//             <SignIn agent='google'/>
//           </Route>
//           <Route path="/oauth2/authorize/alexa">
//             <SignIn agent='alexa'/>
//           </Route>
//           <Route path="/oauth2/token">
//             <SignIn agent='token'/>
//           </Route>
//           <Route path="/devices">
//             <Devices agent='devices'/>
//           </Route>
//           <Route path="/">
//             <SignIn agent='website'/>
//           </Route>

//         </Switch>
//       </Router>
//     </div>
//   );
// }


class App extends Component {
  render() {
    const App = () => (
      <div 
      className="App"
      // style={{
      //   display:'flex',
      //   justifyContent: "center",
      //   alignItems:'center',
      //   backgroundColor: "#000",
      //   flex: 1
      // }}
      >
        <Switch>
          <Route path="/error">
            <Error />
          </Route>

          <Route path="/oauth2/authorize/google">
            <SignIn agent='google'/>
          </Route>
          <Route path="/oauth2/authorize/alexa">
            <SignIn agent='alexa'/>
          </Route>
          <Route path="/oauth2/token">
            <SignIn agent='token'/>
          </Route>
          <Route path="/devices" component={Devices}/>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/google" component={Signin_google} />
        </Switch>
      </div>
    )
    return (
      <Switch >
        <App/>
      </Switch>
    );
  }
}

export default App;



